import { Button, Container, IconButton } from "@mui/material";
import OTPInput from "otp-input-react";
import { ArrowLeft } from "@phosphor-icons/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InputComp, LabelComp, isTextEmpty } from "../../common/common";
import { CircularProgressNovus } from "../../common/components";
import { getUser, updateUserPhone } from "../../common/cookie";
import { setError } from "../Layout/userLayoutSlice";
import {
    useChangePhoneSendOTPMutation,
    useChangePhoneVerifyOTPMutation,
} from "../slices/apiSlice";

function EditPhone() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [values, setValues] = useState({
        step: 0,
        phone: getUser()?.phone ?? "",
        remainingSeconds: 0,
        errorPosition: "",
        errorText: "",
    });
    const { step, phone, remainingSeconds, errorPosition, errorText } = values;
    const [otp, setOTP] = useState("");
    const otpRegex = /^\d{4}$/;
    const canContinueOTP = otpRegex.test(otp);
    const [changePhoneSendOTP, { isLoading: isLoadingSend }] =
        useChangePhoneSendOTPMutation();
    const [changePhoneVerifyOTP, { isLoading: isLoadingVerify }] =
        useChangePhoneVerifyOTPMutation();
    const handleResendOTP = async () => {
        setValues((x) => ({ ...x, remainingSeconds: 60 }));
        handleSubmitSend();
    };
    const handleSubmitSend = async () => {
        try {
            const res = await changePhoneSendOTP({ phone: phone });
            if (res.error) throw res.error;
            setValues((x) => ({ ...x, step: 1 }));
        } catch (error) {
            dispatch(
                setError({
                    errorPosition: "apiErr",
                    errorText: error.data?.message,
                })
            );
        }
    };
    const handleSubmitVerifyAndChange = async () => {
        try {
            if (isTextEmpty(otp) || otp.length !== 4)
                return setValues((x) => ({
                    ...x,
                    errorPosition: "otp",
                    errorText: "Invalid OTP",
                }));
            const res = await changePhoneVerifyOTP({ phone: phone, otp: otp });
            if (res.error) throw res.error;
            updateUserPhone(phone);
            setValues((x) => ({ ...x, step: 0 }));
            dispatch(
                setError({
                    errorPosition: "success",
                    errorText: "Phone number updated",
                })
            );
            setOTP("");
        } catch (error) {
            dispatch(
                setError({
                    errorPosition: "apiErr",
                    errorText: error.data?.message,
                })
            );
        }
    };
    return (
        <Container className="flex flex-1 flex-col py-4">
            {/* top */}
            <div className="flex gap-2 items-center">
                <IconButton onClick={(e) => navigate(-1)}>
                    <ArrowLeft size={24} color="#2B2B2B" />
                </IconButton>
                <span className="title-semibold">Update Phone Number</span>
            </div>
            {/* form */}
            {step === 0 ? (
                <div className="flex flex-col gap-4 flex-1 mt-4 justify-between">
                    {/* email */}
                    <div className="flex flex-col gap-2">
                        {LabelComp("Phone", "phone", errorPosition, errorText)}
                        {InputComp(
                            phone,
                            "phone",
                            setValues,
                            "rounded-xl border_light-gray h-11 body-regular"
                        )}
                    </div>
                    {/* button */}
                    <Button
                        className="btn-primary-large w-full body-semibold"
                        onClick={handleSubmitSend}
                    >
                        {isLoadingSend ? (
                            <CircularProgressNovus size={22} />
                        ) : (
                            "Update Profile"
                        )}
                    </Button>
                </div>
            ) : (
                <div className={"flex flex-col flex-1 gap-3"}>
                    <h3>OTP Verification</h3>
                    {/* body */}
                    <div className="flex flex-col gap-5 mb-3">
                        <div className="d-flex gap-1 body-regular text-dark_gray">
                            Enter the code sent to{" "}
                            <span className="body-semibold text-primary">{phone}</span>
                        </div>
                    </div>
                    {/* otp */}
                    <div className="flex justify-center">
                        <OTPInput
                            value={otp}
                            onChange={setOTP}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            disabled={false}
                            inputClassName="otp_class"
                            inputStyles={{
                                width: "40px",
                                height: "60px",
                                outline: "none",
                                border: "1px solid #E9ECFF",
                                borderRadius: "12px",
                            }}
                            className="w-fit"
                        />
                    </div>
                    {/* remaining seconds */}
                    {remainingSeconds === 0 ? (
                        <div
                            className="flex gap-2 justify-center cursor_pointer body-regular cursor-pointer mb-3"
                            onClick={handleResendOTP}
                        >
                            Didn’t receive OTP?
                            <span className="text-link">Resend</span>
                        </div>
                    ) : (
                        <div className="flex justify-center gap-1 body-regular mb-3">
                            <span>{remainingSeconds}s</span>
                            <span>until resend</span>
                        </div>
                    )}
                    {/* verify */}
                    <Button
                        className={`btn-primary-large w-full body-semibold mt-auto ${!canContinueOTP && "opacity-50"
                            }`}
                        disabled={!canContinueOTP}
                        onClick={handleSubmitVerifyAndChange}
                    >
                        {isLoadingVerify ? <CircularProgressNovus /> : "Verify"}
                    </Button>
                </div>
            )}
        </Container>
    );
}

export default EditPhone;
