import { getUserId } from "./cookie"
import { esewa, fonepay } from "./datasets"

export const isTextEmpty = (text) => {
    if (text === '' || text === undefined || text === null) {
        return true
    }
    else return false
}

export const LabelComp = (labelName, varName, errorPosition, errorText, className) => (
    <div className={`flex items-center justify-between body-regular ${className}`}>
        <span className=''>{labelName}</span>
        {ErrorsComp(varName, errorPosition, errorText)}
    </div>
)

export const InputComp = (value, name, setFn, className, placeholder) => {
    return (
        <input type="text" value={value}
            placeholder={placeholder}
            className={className + ' px-2 body-regular'}
            onChange={(e) => setFn(prev => ({ ...prev, [name]: e.target.value }))} />
    )
}

export const ErrorsComp = (varName, errorPosition, errorText) => {

    return (
        <>
            {
                errorPosition === varName ?
                    <>
                        <span className='text-error'>{errorText}</span>
                    </>
                    :
                    null
            }
        </>
    )
}

// for homepage
export function findIdenticalMod(foundItem, modalData, callback) {
    var foundIdentical = false;
    var foundIdenticalInd = null;
    foundItem.forEach((element, i) => {
        var identicalMods = areArraysOfObjectsIdentical(
            element.selectedModifiers,
            modalData.selectedModifiers
        );
        if (identicalMods && element.spiceLevel === modalData.spiceLevel) {
            foundIdentical = element;
            foundIdenticalInd = i;
        }
    });
    callback(foundIdentical, foundIdentical, foundIdenticalInd);
}
// export function findIdenticalMod(foundItems, modalData, callback) {
//     let foundIdentical = null;
//     let foundIdenticalIndex = null;

//     foundItems.some((element, index) => {
//         const identicalModifiers = areArraysOfObjectsIdentical(
//             element.selectedModifiers,
//             modalData.selectedModifiers
//         );

//         if (identicalModifiers && element.spiceLevel === modalData.spiceLevel) {
//             foundIdentical = element;
//             foundIdenticalIndex = index;
//             return true; // Stops iterating after finding an identical item
//         }
//         return false;
//     });

//     callback(foundIdentical, foundIdentical, foundIdenticalIndex);
// }

// function areArraysOfObjectsIdentical(arr1, arr2) {
//     if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
//         return false;
//     }

//     if (arr1.length !== arr2.length) {
//         return false;
//     }
//     for (let i = 0; i < arr1.length; i++) {
//         const obj1 = arr1[i];
//         const obj2 = arr2[i];
//         if (JSON.stringify(obj1) !== JSON.stringify(obj2)) {
//             return false;
//         }
//     }

//     return true;
// }

function areArraysOfObjectsIdentical(arr1, arr2) {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
        return false;
    }

    if (arr1.length !== arr2.length) {
        return false;
    }

    for (let i = 0; i < arr1.length; i++) {
        if (!isObjectEqual(arr1[i], arr2[i])) {
            return false;
        }
    }

    return true;
}

function isObjectEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}


// get spice text for Spice slider
export const getSpiceText = (val) => {
    switch (val) {
        case 0:
            return "No";
        case 33:
            return "Low";
        case 66:
            return "Medium";
        case 100:
            return "High";
        default:
            return "No"
    }
};

export const getSpiceValue = (val) => {
    switch (val) {
        case "No":
            return 0;
        case "Low":
            return 33;
        case "Medium":
            return 66;
        case "High":
            return 100;
        default:
            return 0
    }
};

// user icon clicked on homepage
export const handleClickUser = (navigate) => {
    if (getUserId()) {
        navigate('/profile')
    }
    else {
        navigate('/login')
    }
}

// format date August 1, 2021
export const formatDate = (dateVal) => {
    const date = new Date(dateVal);
    const customFormattedDate = date.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    return customFormattedDate
}
// format date August 1, 2021
export const formatDate2 = (dateVal) => {
    const date = new Date(dateVal);
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };
    const customFormattedDate = date.toLocaleString('en-US', options);
    return customFormattedDate
}

// get logo of payment type
export const getPaymentImage = (payType) => {
    switch (payType) {
        case 'cash':
            return null;
        case 'esewa':
            return esewa
        case 'fonepay':
            return fonepay
        default:
            return null
    }
}