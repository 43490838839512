import { Button, Slider, styled } from "@mui/material";
import { Minus, Plus, SquareLogo } from "@phosphor-icons/react";
import { MUIModal } from "../../common/MUI";
import {
    findIdenticalMod,
    getSpiceText,
    getSpiceValue,
    isTextEmpty,
} from "../../common/common";
import { AirbnbThumbComponent } from "../../common/components";
import { setUserCartCookie } from "../../common/cookie";
import { spiceDisplayLabel } from "../../common/datasets";
import { setCartItem, setError } from "../Layout/userLayoutSlice";

export const SpiceSlider = styled(Slider)(({ theme }) => ({
    color: "#EB222A",
    height: 8,
    "& .MuiSlider-thumb": {
        height: 27,
        width: 27,
        backgroundColor: "#fff",
        "& .airbnb-bar": {
            height: 9,
            width: 1,
            backgroundColor: "currentColor",
            marginLeft: 1,
            marginRight: 1,
        },
    },
    "& .MuiSlider-track": {
        height: 6,
    },
    "& .MuiSlider-rail": {
        color: "#CACFE3",
        opacity: 1,
    },
}));

// in the modal
export const handleAddOptionQuantityATC = (
    modIndex,
    optionIndex,
    quantity,
    optionPrice,
    modalData,
    setValues
) => {
    var itemMods = [...modalData.selectedModifiers];
    // change quantity
    itemMods[modIndex].options[optionIndex].quantity = Math.max(
        (itemMods[modIndex].options[optionIndex].quantity || 0) + quantity,
        0
    );
    // change hasSelected variable
    itemMods[modIndex].options[optionIndex].hasSelected =
        itemMods[modIndex].options[optionIndex].quantity > 0;
    var totalSelModPrice = modalData.totalSelModPrice + optionPrice;
    setValues((x) => ({
        ...x,
        modalData: {
            ...modalData,
            selectedModifiers: itemMods,
            totalSelModPrice: totalSelModPrice,
        },
    }));
};

// in the modal
export const handleChangeQuantityATC = (quan, modalData, setValues) => {
    const newQuan = modalData.quantity + quan;
    if (newQuan > 0)
        setValues((x) => ({
            ...x,
            modalData: {
                ...modalData,
                quantity: modalData.quantity + quan,
            },
        }));
};

// modal to add items in cart
export const CartModal = (
    modalItem,
    modalData,
    openModal,
    setValues,
    userCart,
    dispatch
) => {
    return (
        <>
            {modalItem && (
                <MUIModal
                    handleClose={(e) => handleCancelAddtoCartModal(setValues)}
                    openModal={openModal && !isTextEmpty(modalItem)}
                >
                    {/* header */}
                    <div className="flex justify-between py-3 px-4 sticky top-0 bg-pure z-50 border-solid border-0 border-b-[1px] border-almost_white">
                        <div className="flex gap-2 items-center">
                            {(modalData.isVeg===true) && (
                                <SquareLogo
                                    color="#33D29C"
                                    weight="fill"
                                    className="title-semibold"
                                />
                            )}
                            {(modalData.isVeg===false) && (
                                <SquareLogo
                                    color="#E22B41"
                                    weight="fill"
                                    className="title-semibold"
                                />
                            )}
                            <span className="title-semibold capitalize">
                                {modalData.name}
                            </span>
                        </div>
                        <div className="flex gap-1 body-regular min-w-fit ml-2">
                            <span className="">
                                Rs. {parseFloat(modalData.discountedPrice).toFixed(2)}
                            </span>
                        </div>
                    </div>
                    {/* body */}
                    <div className="flex flex-col gap-4 mt-2 px-4">
                        {/* modifiers */}
                        {modalData?.selectedModifiers?.map((eachMod, i) => (
                            <div
                                className="flex flex-col gap-2"
                                key={`modalItem_modifier` + i}
                            >
                                <span className="subtitle-semibold text-dark_gray capitalize">
                                    {eachMod.name}
                                </span>
                                {eachMod?.options?.map((eachOp, ind) => (
                                    <div
                                        className="flex justify-between border_light-gray py-2 px-4 rounded-xl items-center"
                                        key={"modalItem_modifier" + ind}
                                        onClick={(e) =>
                                            handleAddOptionQuantityATC(
                                                i,
                                                ind,
                                                1,
                                                eachOp.price,
                                                modalData,
                                                setValues
                                            )
                                        }
                                    >
                                        <div className="flex flex-col gap-[2px]">
                                            <span className="subtitle-semibold capitalize">
                                                {eachOp.name}
                                            </span>
                                            <span className="caption">+Rs. {eachOp.price}</span>
                                        </div>
                                        {/* quantity */}
                                        <div className="flex gap-1">
                                            {/* minus */}
                                            {eachOp.quantity > 0 && (
                                                <Button
                                                    className="bg-dark_gray rounded-[3px] w-8 h-8 text-pure p-0"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleAddOptionQuantityATC(
                                                            i,
                                                            ind,
                                                            -1,
                                                            eachOp.price,
                                                            modalData,
                                                            setValues
                                                        );
                                                    }}
                                                >
                                                    <Minus size={20} color="#fff" />
                                                </Button>
                                            )}
                                            {eachOp.quantity > 0 && (
                                                <Button className="rounded-[3px] w-8 h-8 p-0 text-text subtitle">
                                                    {eachOp.quantity}
                                                </Button>
                                            )}
                                            {/* add */}
                                            <Button
                                                className="bg-dark_gray rounded-[3px] w-8 h-8 text-pure p-0"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleAddOptionQuantityATC(
                                                        i,
                                                        ind,
                                                        1,
                                                        eachOp.price,
                                                        modalData,
                                                        setValues
                                                    );
                                                }}
                                            >
                                                <Plus size={20} color="#fff" />
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                        {/* spice level */}
                        {modalItem.hasSpiceLevel && (
                            <div className="flex flex-col gap-2">
                                <span className="subtitle-semibold text-dark_gray">
                                    Spice Level
                                </span>
                                <div className="flex flex-col pl-5 justify-center">
                                    <span className="body-semibold text-secondary self-center">
                                        {modalData.spiceLevel}
                                    </span>
                                    <SpiceSlider
                                        marks={spiceDisplayLabel}
                                        slots={{ thumb: AirbnbThumbComponent }}
                                        getAriaLabel={(index) =>
                                            index === 0 ? "Minimum price" : "Maximum price"
                                        }
                                        value={getSpiceValue(modalData.spiceLevel)}
                                        onChange={(e, newVal) => {
                                            setValues((x) => ({
                                                ...x,
                                                modalData: {
                                                    ...modalData,
                                                    spiceLevel: getSpiceText(newVal),
                                                },
                                            }));
                                        }}
                                        step={null}
                                    />
                                </div>
                            </div>
                        )}
                        {/* quantity */}
                        <div className="flex justify-between">
                            <span className="subtitle-semibold text-dark_gray">Quantity</span>
                            <div className="flex gap-[6px]">
                                <div
                                    onClick={(e) =>
                                        handleChangeQuantityATC(-1, modalData, setValues)
                                    }
                                    className="w-8 h-8 bg-dark_gray flex
                   justify-center items-center rounded-[3px]"
                                >
                                    <Minus size={20} color="#fff" />
                                </div>
                                <div className="w-8 h-8 subtitle flex items-center justify-center">
                                    {modalData.quantity}
                                </div>
                                <div
                                    onClick={(e) =>
                                        handleChangeQuantityATC(1, modalData, setValues)
                                    }
                                    className="w-8 h-8 bg-dark_gray flex justify-center items-center rounded-[3px]"
                                >
                                    <Plus size={20} color="#fff" />
                                </div>
                            </div>
                        </div>
                        {/* message */}
                        <div className="flex flex-col gap-2 mb-2">
                            <span className="text-dark_gray subtitle-semibold">Message</span>
                            <textarea
                                name="message"
                                id="message"
                                rows="4"
                                value={modalData.note}
                                onChange={(e) =>
                                    setValues((x) => ({
                                        ...x,
                                        modalData: { ...modalData, note: e.target.value },
                                    }))
                                }
                                className="rounded-xl border_light-gray focus:outline-none p-1 w-full body-regular"
                            ></textarea>
                        </div>
                    </div>
                    {/* buttons */}
                    <div
                        className="flex justify-end self-stretch gap-2
           border-solid border-0 border-t-2 border-almost_white py-3 px-4
          sticky bottom-0 bg-pure"
                    >
                        <Button
                            className="body-semibold w-28 text-primary"
                            onClick={(e) => handleCancelAddtoCartModal(setValues)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="btn-primary-small w-28 rounded-xl"
                            onClick={(e) =>
                                handleAddtoCart(userCart, modalData, dispatch, setValues)
                            }
                        >
                            Add
                        </Button>
                    </div>
                </MUIModal>
            )}
        </>
    );
};

// cancel / close add to cart modal
export const handleCancelAddtoCartModal = (setValues) => {
    setValues((x) => ({
        ...x,
        openModal: false,
        modalItem: null,
        modalData: {
            name: "",
            itemId: "",
            variantId: "",
            quantity: 1,
            price: null,
            spiceLevel: "Low",
            note: "",
            selectedModifiers: [],
        },
    }));
};

// add to cart button clicked
export const handleAddtoCart = async (
    userCart,
    modalData,
    dispatch,
    setValues,
    optionalFn
) => {
    var existingItems = JSON.parse(JSON.stringify(userCart.items));
    const foundItem = existingItems.find((x) => x.itemId === modalData.itemId);
    const handleIdenticalItem = (identicalIndex) => {
        var addPrice = 0;
        const updatedExistingItems = existingItems.map((item, index) => {
            if (index === identicalIndex) {
                addPrice =
                    modalData.quantity *
                    (modalData.discountedPrice + modalData.totalSelModPrice);
                const updatedQuantity = item.quantity + modalData.quantity;
                return {
                    ...item,
                    quantity: updatedQuantity,
                    totalPriceItem: item.totalPriceItem + addPrice,
                };
            }
            return item;
        });
        existingItems = updatedExistingItems;
        const updatedCart = getNewCartQuantityAdd(
            existingItems,
            userCart,
            addPrice
        );
        dispatch(setCartItem({ cart: updatedCart }));
        setUserCartCookie(updatedCart);
        dispatch(
            setError({ errorPosition: "success", errorText: "Added to cart" })
        );
        setValues && handleCancelAddtoCartModal(setValues);
    };
    const handleNewItem = () => {
        var newCart = getNewCartItemPush(modalData, userCart);
        dispatch(
            setCartItem({
                cart: newCart,
            })
        );
        setUserCartCookie(newCart);
        dispatch(
            setError({ errorPosition: "success", errorText: "Added to cart" })
        );
        setValues && handleCancelAddtoCartModal(setValues);
    };

    if (foundItem) {
        findIdenticalMod(
            existingItems,
            modalData,
            (identicalMods, returnValue, identicalIndex) => {
                if (identicalMods && modalData.spiceLevel === returnValue?.spiceLevel) {
                    handleIdenticalItem(identicalIndex);
                } else {
                    handleNewItem();
                }
            }
        );
    } else {
        handleNewItem();
    }

    optionalFn?.();
};

// added new item in cart
const getNewCartItemPush = (modalData, userCart) => {
    var totalModal =
        modalData.quantity *
        (modalData.discountedPrice + modalData.totalSelModPrice);
    modalData.totalPriceItem = totalModal;
    var cart = JSON.parse(JSON.stringify(userCart));
    cart.items.push(modalData);
    cart.subTotal += +modalData.totalPriceItem;
    return cart;
};

// change just quantity, for identical item added to cart
const getNewCartQuantityAdd = (items, userCart, addPrice) => {
    var cart = JSON.parse(JSON.stringify(userCart));
    cart.items = items;
    cart.subTotal += addPrice;
    return cart;
};
