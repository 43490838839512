import { Avatar, Button, Container, IconButton } from "@mui/material";
import {
    ArrowLeft,
    CaretRight,
    ClockCounterClockwise,
    CoinVertical,
    Phone,
    UserCircle,
} from "@phosphor-icons/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUser, handleUserSignout } from "../../common/cookie";
import { selected_service } from "../Layout/userLayoutSlice";

function UserProfile() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const selected = useSelector(selected_service)
    return (
        <Container className="flex flex-col flex-1 py-4">
            <div className="flex flex-col">
                {/* top */}
                <div className="flex gap-2 items-center">
                    <IconButton onClick={(e) => navigate(`/?services=${selected}`)}>
                        <ArrowLeft size={24} color="#2B2B2B" />
                    </IconButton>
                    <span className="title-semibold">Profile</span>
                </div>
                {/* details */}
                <div className="flex gap-[10px] mt-4 items-center">
                    <Avatar sx={{ width: 50, height: 50 }} src={getUser()?.image}>
                        {getUser()?.name?.[0]}
                    </Avatar>
                    <div className="flex flex-col overflow-hidden">
                        <span className="body-semibold">{getUser()?.name}</span>
                        <span className="subtitle text-dark_gray">{getUser()?.email}</span>
                    </div>
                </div>
                {/* tiles */}
                <div className="flex flex-col rounded-xl border_light-gray mt-6 mb-2">
                    {/* edit profile */}
                    <Button
                        className="border-b_light-gray p-4 justify-between body-regular text-text"
                        onClick={(e) => navigate("edit")}
                    >
                        <div className="flex gap-3">
                            <UserCircle size={24} className="text-dark_gray" />
                            <span>Edit Profile</span>
                        </div>
                        <CaretRight size={24} className="text-dark_gray" />
                    </Button>
                    {/* edit phone */}
                    <Button
                        className="border-b_light-gray p-4 justify-between body-regular text-text"
                        onClick={(e) => navigate("editPhone")}
                    >
                        <div className="flex gap-3">
                            <Phone size={24} className="text-dark_gray" />
                            <span>Edit Phone Number</span>
                        </div>
                        <CaretRight size={24} className="text-dark_gray" />
                    </Button>
                    {/* view orders */}
                    <Button
                        className="border-b_light-gray p-4 body-regular text-text justify-between"
                        onClick={(e) => navigate("history")}
                    >
                        <div className="flex gap-3">
                            <ClockCounterClockwise size={24} className="text-dark_gray" />
                            <span>View History</span>
                        </div>
                        <CaretRight size={24} className="text-dark_gray" />
                    </Button>
                    {/* redeem points */}
                    <Button className="border-b_light-gray p-4 body-regular text-text justify-between opacity-50">
                        <div className="flex gap-3">
                            <CoinVertical size={24} className="text-dark_gray" />
                            <span className="text-left">Redeem Points (Coming Soon)</span>
                        </div>
                    </Button>
                </div>
            </div>
            {/* sign out */}
            <Button
                className="border_light-gray p-4 body-regular text-text justify-between rounded-xl mt-auto"
                onClick={(e) => handleUserSignout(navigate, dispatch, selected)}
            >
                <div className="flex gap-3">
                    <ClockCounterClockwise size={24} className="text-dark_gray" />
                    <span>Sign out</span>
                </div>
            </Button>
        </Container>
    );
}

export default UserProfile;
