import { Button, Container } from "@mui/material";
import { ArrowLeft, SquareLogo } from "@phosphor-icons/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoadingPageGeneral } from "../ErrorPages/LoadingPage";
import { handleAddtoCart } from "../HomePage/AddToCartModal";
import { handleOpenNotifyWaiter } from "../Layout/Layout";
import { ad_check, selected_service, user_cart } from "../Layout/userLayoutSlice";

const CheckComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userCart = useSelector(user_cart);
    const data = useSelector(ad_check);
    const selected = useSelector(selected_service)
    const handleOrderAgain = (val) => {
        var item = { ...val };
        // 1. calcuate totalSelModPrice, and add hasSelected to option of modifier
        var totalSelModPrice = 0;
        const updatedModifiers = (item?.modifiers || []).map((modifier) => {
            const updatedOptions = modifier.options.map((option) => {
                const price = option.price;
                const quantity = option.quantity;
                totalSelModPrice += price * quantity;

                // Create a shallow copy of the option and add the new key-value pair
                return { ...option, hasSelected: true };
            });

            // Create a new modifier object with the updated options
            return { ...modifier, options: updatedOptions };
        });
        // Replace the modifiers array in the original item with the updated array
        if (item?.modifiers) {
            item.modifiers = updatedModifiers;
        }
        // 2. set name, discountedPrice, totalSelModPrice and totalPriceItem
        item = {
            name: item?.variant?.name ?? "",
            itemId: item.item?.id,
            variantId: item.variant?.id,
            quantity: item.quantity,
            totalSelModPrice: totalSelModPrice,
            price: item.price,
            discountedPrice: item?.variant?.discountedPrice ?? 0,
            discountAmount: item.discountAmount,
            spiceLevel: item.spiceLevel,
            selectedModifiers: item.modifiers,
            totalPriceItem: item.quantity * (totalSelModPrice + item.discountedPrice),
        };
        //   3. add to cart
        handleAddtoCart(userCart, item, dispatch);
    };
    const handleNavigate = () => {
        navigate(`/?services=${selected}`)
    }
    if (!data) LoadingPageGeneral();
    else
        return (
            <Container
                style={{ height: window.innerHeight }}
                className="flex flex-col gap-4 pt-4 flex-1 overflow-hidden"
            >
                {/* top */}
                <div className="flex h-7 justify-between mb-3">
                    <div className="flex items-center gap-2">
                        <ArrowLeft
                            size={24}
                            color="#2B2B2B"
                            onClick={handleNavigate}
                        />
                        <span className="title-semibold">Open Check</span>
                    </div>
                </div>

                {/* recepit */}
                <div className="flex flex-col overflow-x-hidden overflow-y-auto flex-grow gap-2 pr-2">
                    {data?.orderInfo?.items?.map((each, i) => (
                        <div
                            key={"check_each_" + i}
                            className={`flex flex-col gap-2 border-solid border-0 border-light_gray pb-3 ${i === data?.orderInfo?.items?.length - 1 ? "" : "border-b-[1px]"
                                }`}
                        >
                            {/* item */}
                            <div className="flex flex-col gap-1">
                                {/* item details */}
                                <div className="flex flex-row justify-between">
                                    {/* name */}
                                    <div className="flex">
                                        {/* veg sign */}
                                        <div className="flex flex-grow">
                                            {(each.variant?.isVeg===true) && (
                                                <SquareLogo
                                                    color="#33D29C"
                                                    weight="fill"
                                                    className="title-semibold"
                                                />
                                            )} 
                                           {(each.variant?.isVeg===false) &&(
                                                <SquareLogo
                                                    color="#E22B41"
                                                    weight="fill"
                                                    className="title-semibold"
                                                />
                                            )}
                                        </div>
                                        {/* name */}
                                        <div className="flex flex-wrap subtitle-semibold text-primary">
                                            <span className="ml-1 capitalize">{each.item?.name}</span>
                                            <span className="ml-1 capitalize">
                                                {each.hasVariants &&
                                                    each.variant?.name}
                                            </span>
                                            <span className="ml-1">x{each.quantity}</span>
                                        </div>
                                    </div>
                                    {/* price */}
                                    <div className="body-regular flex min-w-fit">
                                        Rs. {each.variant?.price}
                                    </div>
                                </div>
                                {/* mods and spice level */}
                                <div className="flex justify-between pl-4">
                                    {/* modifiers */}
                                    <div className="flex flex-col gap-1 caption text-dark_gray">
                                        {each.modifiers?.map((eachMod, indMod) => (
                                            <div
                                                key={"review_item_mod_" + i + indMod}
                                                className="flex flex-col gap-1"
                                            >
                                                {eachMod.options?.map((eachOp, indOp) => (
                                                    <div
                                                        key={"review_item_mod_option+" + i + indMod + indOp}
                                                        className={`flex justify-between`}
                                                    >
                                                        <div className="flex gap-1">
                                                            + {eachOp.name}
                                                            {eachOp.quantity > 1 && (
                                                                <span>x{eachOp.quantity}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                        {/* spice level */}
                                        {each.spiceLevel && <div className="flex gap-1 justify-between subtitle text-dark_gray">
                                            <span>+ Spice Level - </span>
                                            <span>{each.spiceLevel}</span>
                                        </div>}
                                    </div>
                                </div>
                                {/* note */}
                                <span className="text-dark_gray italic caption">
                                    {each.note}
                                </span>
                                {/* order again */}
                                <Button
                                    className="py-[6px] px-2 subtitle-semibold rounded-5
                 border_primary-default text-primary w-fit h-8 flex ml-auto"
                                    onClick={(e) => handleOrderAgain(each)}
                                >
                                    Order Again
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>

                {/* button */}
                <div
                    className="flex flex-col mt-auto gap-3
         py-4 border-solid border-0 border-t-[1px] border-light_gray"
                >
                    <div className="flex flex-col gap-2">
                        <div className="flex justify-between body-regular text-dark_gray">
                            <span>Subtotal</span>
                            <span>
                                Rs. {parseFloat(Math.ceil(data?.orderInfo?.subTotal)).toFixed(2)}
                            </span>
                        </div>
                        {data.orderInfo?.taxTotal ? (
                            <div className="flex justify-between body-regular text-dark_gray">
                                <span>Tax</span>
                                <span>
                                    Rs. {parseFloat(Math.ceil(data?.orderInfo?.taxTotal)).toFixed(2)}
                                </span>
                            </div>
                        ) : null}
                        <div className="flex justify-between body-semibold">
                            <span>Total</span>
                            <span>Rs. {parseFloat(Math.ceil(data.orderInfo?.total)).toFixed(2)}</span>
                        </div>
                    </div>
                    <Button
                        className="btn-primary-large w-full mt-auto"
                        onClick={(e) => {
                            handleOpenNotifyWaiter(dispatch);
                        }}
                    >
                        Ready for payment
                    </Button>
                </div>
            </Container>
        );
}

export default CheckComponent;
