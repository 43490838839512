import Cookies from "js-cookie";
import { clearSocket, setBusinessTableId, setError, setOrderStatus, updateCartInfo } from "../Pages/Layout/userLayoutSlice";

const cookieName_cart = 'novus_user_cart'

export function getCookie() {
    const cookieName = 'novus_user'
    return Cookies.get(cookieName) ? JSON.parse(Cookies.get(cookieName)) : null;
}

export function getUser() {
    var cookie = getCookie()
    if (!cookie) return null
    else {
        var user = cookie?.user
        return user ?? null
    }
}

export function updateUser(updatedName, updatedAddress, image) {
    const cookieName = 'novus_user'
    var cookie = getCookie()
    if (!cookie) return null
    else {
        var user = { ...cookie, user: { ...cookie.user, name: updatedName, tempAddress: updatedAddress, image } }
        const options = { sameSite: 'strict', expires: null }
        Cookies.set(cookieName, JSON.stringify(user), options);
    }
}

export function updateUserPhone(updatedPhone) {
    const cookieName = 'novus_user'
    var cookie = getCookie()
    if (!cookie) return null
    else {
        var user = { ...cookie, user: { ...cookie.user, phone: updatedPhone } }
        const options = { sameSite: 'strict', expires: null }
        Cookies.set(cookieName, JSON.stringify(user), options);
    }
}

export function getUserId() {
    var cookie = getCookie()
    if (!cookie) return null
    else {
        var user = cookie?.user?.id
        return user ?? null
    }
}

export function getUserToken() {
    var cookie = getCookie()
    if (!cookie) return null
    else {
        var user = cookie?.access_token
        return user ?? null
    }
}

export function getBusinessId() {
    var cookie = getCookie()
    if (!cookie) return null
    else {
        return cookie?.businessId ?? null
    }
}

export function getTableId() {
    var cookie = getCookie()
    if (!cookie) return null
    else {
        return cookie?.tableId ?? null
    }
}

export function saveLoginCookie(value, expiresAt) {
    var updatedValue = { ...value }
    const { businesses, ...rest } = value.user;
    updatedValue.businessId = getBusinessId()
    updatedValue.tableId = getTableId()
    updatedValue.info = getCookie()?.info
    updatedValue.user = {...rest}
    updatedValue = JSON.stringify(updatedValue)
    const cookieName = 'novus_user'
    const expiryTime = new Date(Date.now() + expiresAt);
    const options = { sameSite: 'strict', expires: expiryTime }
    Cookies.set(cookieName, updatedValue, options);
}

// sign out
export function handleUserSignout(navigate, dispatch, selected) {
    const cookieName = 'novus_user'
    var existingCookie = { ...getCookie() }
    var newCookie = {
        tableId: existingCookie.tableId,
        businessId: existingCookie.businessId,
        info: existingCookie.info
    }
    Cookies.remove(cookieName)
    const options = { sameSite: 'strict', }
    Cookies.set(cookieName, JSON.stringify(newCookie), options);
    navigate(`/?services=${selected}`)
    dispatch(setError({
        errorPosition: 'success',
        errorText: 'logged out'
    }))
    dispatch(clearSocket())
    dispatch(setOrderStatus({
        userCurrentOrders: [],
        numberOfOrders: 0
    },))
}

export const saveScannedTable = (businessId, tableId, info) => {
    const cookieName = 'novus_user'
    const options = { sameSite: 'strict' }
    const currentCookieData = Cookies.get(cookieName) ? JSON.parse(Cookies.get(cookieName)) : {};
    currentCookieData.businessId = businessId
    currentCookieData.tableId = tableId
    currentCookieData.info = info
    Cookies.set(cookieName, JSON.stringify(currentCookieData), options);
    return
}

// cart
export const setUserCartCookie = (cart) => {
    saveCartCookie(cart)
}

// for review order page
export const updateUserCartCookieReview = (newCart) => {
    saveCartCookie(newCart)
}

function saveCartCookie(value) {
    try {
        localStorage.setItem(cookieName_cart, JSON.stringify(value));
    } catch (error) {
    }
}

export const setInitailCart = (businessId, tableId, dispatch) => {
    saveCartCookie(
        {
            businessId: businessId,
            tableId: tableId,
            type: "dine_in",
            userId: '',
            origin: "contactless_app",
            subTotal: 0,
            discount: 0,
            items: [
            ],
        }
    )
    dispatch(updateCartInfo(
        {
            businessId: businessId,
            tableId: tableId,
            type: "dine_in",
            userId: '',
            origin: "contactless_app",
            subTotal: 0,
            discount: 0,
            items: [
            ],
        }
    ))
    dispatch(setBusinessTableId({
        businessId, tableId
    }))
}