import { IconButton } from "@mui/material";
import { BookOpenText } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PoweredBy from '../../assets/poweredBy.svg';
import { getCookie } from "../../common/cookie";

// const data = {
//   name : 'Hyatt Regency',
//   table: 'Room 540',
//   logo : 'https://picsum.photos/100/50',
//   imageSliders:[
//     'https://picsum.photos/360/180',
//     'https://picsum.photos/360/180',
//     'https://picsum.photos/360/180'
//   ],
//   services : [
//     {
//       title : 'In room dining',
//       url:'',
//       image : 'https://picsum.photos/360/150'
//     },
//     {
//       title : 'Activity',
//       url:'',
//       image : 'https://picsum.photos/360/152'
//     }
//   ]
// }

function LandingPage() {
  const navigate = useNavigate()
  const data = getCookie()?.info?.businessInfo ?? null
  const tableInfo = getCookie()?.info?.tableInfo ?? null
  const renderServices = (each,index) => (
    <div className="" key={each.title+index}
    onClick={e=>{
      navigate('/?services='+each.id)
    }}
    style={{
      backgroundImage: `url(${each.image})`,
      height: '150px',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius:'12px'
    }}>
      <div className="w-full h-full rounded-xl flex justify-center items-center" style={{
        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)'
      }}>
      <h3 className="text-white uppercase">{each.name}</h3>
      </div>
    </div>
  )
  if(!data) return
  return (
    <div className="flex flex-col pb-4 w-screen">
      {/* nav */}
      <div className="flex justify-between mx-4 items-center my-1">
        <img
          src={data?.logo} 
          alt="" 
          className="h-12 w-auto" 
         />
         <div className="flex gap-3 items-center">
          <div className="py-[2px] px-2 rounded-[3px] border border-solid border-icon_dark h-fit">{tableInfo?.name}</div>
          <IconButton className="border border-solid border-light_gray">
            <BookOpenText className="text-icon_dark" />
          </IconButton>
         </div>
      </div>
      {/* body */}
      <div className="flex flex-col flex-1">
        {/* carousel */}
        <Carousel images={data?.imageSliders || []} />
        {/* greeting */}
        <div
          className={`flex flex-col mx-4 ${
            data?.imageSliders?.length > 0 && "mt-4"
          }`}
        >
          <div className="title">
            Greetings from{" "}
            <span className="title-semibold">{data?.name}</span>{" "}
          </div>
          <span className="body-regular text-dark_gray">
            How can we assist you?
          </span>
        </div>
        {/* services */}
        <div className="flex flex-col gap-3 mx-4 mt-4">
            {data?.services?.map(renderServices)}
        </div>
        {/* powered by */}
        <div className="mb-4 pt-5 flex mt-auto justify-center">
          <img src={PoweredBy} alt="" />
        </div>
      </div>
    </div>
  );
}

export default LandingPage;

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [images]);

  const goToIndex = (index) => {
    if (index === images.length) return setCurrentIndex(0);
    else if (index < 0) return setCurrentIndex(images.length - 1);
    if (index >= 0 && index < images.length) setCurrentIndex(index);
  };

  const [touchStartX, setTouchStartX] = useState(null);

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (touchStartX === null) {
      return;
    }

    const touchEndX = e.touches[0].clientX;
    const deltaX = touchEndX - touchStartX;
    if (deltaX > 0) {
      // Swipe right
      goToIndex(currentIndex - 1);
    } else if (deltaX < 0) {
      // Swipe left
      goToIndex(currentIndex + 1);
    }

    setTouchStartX(null);
  };
  if (images.length === 0) return null;
  return (
    <div
      className="carousel relative overflow-hidden bg-gray-200 h-[50vw]"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <div className="carousel-inner h-full flex transition-transform duration-1000 ease-in-out relative">
        {images.map((slide, index) => (
          <div
            key={"car_image_" + index}
            className={`carousel-item absolute w-full transition-transform ease-in-out duration-500`}
            style={{
              transform: `translateX(${(index - currentIndex) * 100}%)`,
            }}
          >
            <div
              className="w-full h-[50vw] ml-auto bg-cover bg-center rounded-5"
              style={{
                backgroundImage: `url(${slide})`,
              }}
            />
          </div>
        ))}
      </div>

      <div className="carousel-bullets flex justify-center absolute left-1/2 bottom-2 transform -translate-x-1/2 -translate-y-1/2">
        {images.map((_, index) => (
          <div
            key={index}
            className={`bullet h-2 w-2 bg-gray-500 rounded-full mx-1 cursor-pointer ${
              index === currentIndex ? "bg-white" : ""
            }`}
            onClick={() => goToIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};