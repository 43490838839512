import { Box, Button, Container } from "@mui/material";
import { Minus, Plus, SquareLogo, X } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import empty_pruchase from "../../assets/empty_pruchase.svg";
import { CircularProgressNovus } from "../../common/components";
import {
    getUserId,
    setUserCartCookie,
    updateUserCartCookieReview,
} from "../../common/cookie";
import Login from "../Auth/Login";
import { selected_service, setCartItem, setError, user_cart } from "../Layout/userLayoutSlice";
import { useCreateOrderMutation } from "../slices/apiSlice";

// bottom button showing items in cart
export const ReviewOrderButton = (userCart, navigate) => (
    <>
        {userCart?.items?.length > 0 && (
            <Button
                className="flex justify-between items-start rounded-[0px] rounded-t-lg text-pure
         bg-primary p-4 fixed bottom-0 w-[100vw] title-semibold"
                onClick={(e) => navigate("review")}
            >
                <span>Review order</span>
                <span>
                    {userCart?.items.length} item{userCart?.items.length > 1 && "s"}
                </span>
            </Button>
        )}
    </>
);

export const ReviewOrderDrawerPage = () => {
    const location = useLocation()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selected = useSelector(selected_service)
    useEffect(() => {
        window.scrollTo({
            top: 0, left: 0, behavior: 'smooth'
        })
    }, [location])
    const [loginView, setLoginView] = useState(false); //for review order page
    const [createOrder, { isLoading: isLoadingReviewOrder }] =
        useCreateOrderMutation(); //for review order page
    const userCart = useSelector(user_cart);
    const items = userCart?.items ?? [];
    const toggleDrawer = (open) => (event) => {
        navigate(`/?services=${selected}`);
        setLoginView(false);
    };
    const handlePlaceOrder = async () => {
        if (getUserId()) {
            try {
                await createOrder(userCart).unwrap();
                dispatch(
                    setCartItem({
                        cart: {
                            ...userCart,
                            items: [],
                            subTotal: 0,
                            discount: 0,
                        },
                    })
                );
                setUserCartCookie({ ...userCart, items: [], price: 0, discount: 0 });
                navigate("/order_status");
            } catch (error) {
                dispatch(
                    setError({
                        errorPosition: "apiErr",
                        errorText: error.data?.message,
                    })
                );
            }
        } else {
            setLoginView(true);
        }
    };
    const handleChangeQuantity = (itemVal, quan, index) => {
        var item = { ...itemVal };
        var items_all = [...items];
        // 1. update new quan
        item.quantity += quan; //1
        // 2. find added/subtracted price
        const indPrice =
            quan * (itemVal.discountedPrice + itemVal.totalSelModPrice);
        // 3. update totalPriceItem
        item.totalPriceItem += indPrice;
        // 4. find new newSubTotal
        const newSubTotal = userCart.subTotal + indPrice;
        // 5. get new cart
        const newCart = {
            ...userCart,
            items: items_all,
            subTotal: newSubTotal,
        };
        // update
        items_all[index] = item;
        if (item.quantity === 0) {
            // remove the item
            items_all.splice(index, 1);
        }
        dispatch(
            setCartItem({
                cart: newCart,
            })
        );
        updateUserCartCookieReview(newCart);
    };
    return (
        <div className="flex w-full h-auto">
            <Box role="presentation" className="flex w-full">
                {loginView ? (
                    <Container className="flex flex-col gap-4 py-4">
                        <Login />
                    </Container>
                ) : (
                    <Container
                        className="flex flex-col gap-4 py-4 overflow-hidden"
                    >
                        {/* top */}
                        <div className="flex h-7 justify-between">
                            <div className="flex items-center gap-2">
                                <span className="title-semibold">Review Order</span>
                            </div>
                            <X size={24} onClick={toggleDrawer(false)} />
                        </div>
                        {/* no items */}
                        {items.length === 0 ? (
                            <div className="flex-1 flex my-auto flex-col justify-center items-center gap-8">
                                <img className="w-40 h-52" src={empty_pruchase} alt="" />
                                <div className="flex flex-col gap-2 items-center">
                                    <span className="title-semibold">
                                        You have not added anything yet
                                    </span>
                                    <span className="subtitle text-dark_gray">
                                        Add something to view them here
                                    </span>
                                    <Button
                                        className="btn-primary-medium"
                                        onClick={toggleDrawer(false)}
                                    >
                                        Go to home
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div
                                className={`flex flex-col flex-grow gap-2 overflow-hidden`}
                            >
                                {/* items */}
                                <div className="flex flex-col gap-1 overflow-y-auto pr-2">
                                    {items?.map((each, i) => (
                                        <div
                                            key={"review_item_" + i}
                                            className="flex flex-col gap-1 border-solid border-0 border-b-[1px]
                         border-almost_white pb-3"
                                        >
                                            {/* item details */}
                                            <div className="flex flex-row justify-between">
                                                {/* name */}
                                                <div className="flex items-center">
                                                    {/* veg sign */}
                                                    <div className="flex flex-grow">
                                                        {(each?.isVeg===true) && (
                                                            <SquareLogo
                                                                color="#33D29C"
                                                                weight="fill"
                                                                className="title-semibold"
                                                            />
                                                        )} 
                                                        {(each?.isVeg===false) &&(
                                                            <SquareLogo
                                                                color="#E22B41"
                                                                weight="fill"
                                                                className="title-semibold"
                                                            />
                                                        )}
                                                    </div>
                                                    {/* name */}
                                                    <div className="flex flex-wrap subtitle-semibold text-primary capitalize">
                                                        <span className="ml-1">{each.name}</span>
                                                        <span className="ml-1">
                                                            {each.hasVariant && each.variantName}
                                                        </span>
                                                    </div>
                                                </div>
                                                {/* price */}
                                                <div className="body-regular flex min-w-fit">
                                                    Rs. {each.totalPriceItem}
                                                </div>
                                            </div>
                                            {/* modifiers ,spice and quantity */}
                                            <div className="flex justify-between pl-4">
                                                {/* modifiers */}
                                                <div className="flex flex-col gap-1 caption text-dark_gray">
                                                    {each.selectedModifiers?.map((eachMod, indMod) => (
                                                        <div
                                                            key={"review_item_mod_" + i + indMod}
                                                            className="flex flex-col gap-1"
                                                        >
                                                            {eachMod.options?.map((eachOp, indOp) => (
                                                                <div
                                                                    key={
                                                                        "review_item_mod_option+" +
                                                                        i +
                                                                        indMod +
                                                                        indOp
                                                                    }
                                                                    className={`flex justify-between ${!eachOp.hasSelected ? "hidden" : ""
                                                                        }`}
                                                                >
                                                                    <div className="flex gap-1">
                                                                        + {eachOp.name}
                                                                        {eachOp.quantity > 1 && (
                                                                            <span>x{eachOp.quantity}</span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                    {/* spice level */}
                                                    {each.spiceLevel && <div className="flex gap-1 justify-between subtitle text-dark_gray">
                                                        <span>+ Spice Level - </span>
                                                        <span>{each.spiceLevel}</span>
                                                    </div>}
                                                </div>
                                                {/* quantity */}
                                                <div className="flex justify-between col-span-1 mt-2">
                                                    <div className="flex gap-[6px]">
                                                        <div
                                                            onClick={(e) => handleChangeQuantity(each, -1, i)}
                                                            className="w-8 h-8 bg-dark_gray flex
                         justify-center items-center rounded-[3px]"
                                                        >
                                                            <Minus size={20} color="#fff" />
                                                        </div>
                                                        <div className="w-8 h-8 subtitle flex items-center justify-center">
                                                            {each.quantity}
                                                        </div>
                                                        <div
                                                            onClick={(e) => handleChangeQuantity(each, 1, i)}
                                                            className="w-8 h-8 bg-dark_gray flex justify-center items-center rounded-[3px]"
                                                        >
                                                            <Plus size={20} color="#fff" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* note */}
                                            <span className="text-dark_gray italic caption">
                                                {each.note}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                                {/* total */}
                                <div className="flex flex-col gap-2 border-solid border-0 border-t-[1px] mt-auto
                                     border-light_gray">
                                        <div className="flex flex-col gap-2">
                                            {/* subtotal */}
                                            <div className="flex justify-between text-dark_gray body-regular">
                                                <span>Subtotal</span>
                                                <span>
                                                    Rs. {parseFloat(userCart.subTotal).toFixed(2)}
                                                </span>
                                            </div>
                                            {/* discount */}
                                            {userCart.discount > 0 && (
                                                <div className="flex justify-between text-dark_gray body-regular">
                                                    <span>Discount</span>
                                                    <span className="text-error">
                                                        Rs. {parseFloat(userCart.discount).toFixed(2)}
                                                    </span>
                                                </div>
                                            )}
                                            {/* taxes */}
                                            {userCart.taxes?.map((eachTax, indTax) => (
                                                <div
                                                    key={"item_tax_" + indTax}
                                                    className="flex justify-between text-dark_gray body-regular"
                                                >
                                                    <span className="capitalize">{eachTax.name}</span>
                                                    <span>
                                                        Rs.{" "}
                                                        {parseFloat(getTax(eachTax, userCart)).toFixed(2)}
                                                    </span>
                                                </div>
                                            ))}
                                            {/* total */}
                                            <div className="flex justify-between text-text body-semibold">
                                                <span>Total</span>
                                                <span>
                                                    Rs. {parseFloat(getTotalWithTax(userCart)).toFixed(2)}
                                                </span>
                                            </div>
                                        </div>
                                        {/* place order */}
                                        <Button
                                            className="btn-primary-large w-full"
                                            onClick={handlePlaceOrder}
                                        >
                                            {isLoadingReviewOrder ? (
                                                <CircularProgressNovus />
                                            ) : (
                                                `Place order Rs. ${parseFloat(
                                                    getTotalWithTax(userCart)
                                                ).toFixed(2)}`
                                            )}
                                        </Button>
                                </div>
                            </div>
                        )}
                    </Container>
                )}
            </Box>
        </div>
    );
};

const getTotalWithTax = (userCart) => {
    var totalNow = userCart.subTotal - userCart.discount;
    var totalFinal = userCart.subTotal - userCart.discount;
    userCart.taxes?.forEach((element) => {
        if (element.type === "percent") {
            totalFinal = totalFinal + (element.value * totalNow) / 100;
        } else if (element.type === "amount") {
            totalFinal = totalFinal + element.value;
        }
    });
    return totalFinal;
};

const getTax = (tax, userCart) => {
    var total = userCart.subTotal - userCart.discount;
    var returnVal = 0;
    if (tax.type === "percent") {
        returnVal = (tax.value * total) / 100;
    } else if (tax.type === "amount") {
        returnVal = total === 0 ? 0 : tax.value;
    }
    return returnVal;
};
