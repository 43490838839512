import atmosphere_img from '../assets/atmosphere.svg'
import beverage_quality_img from '../assets/beverage_quality.svg'
import cooking from '../assets/cooking.gif'
import cooking_large from '../assets/cooking_large.gif'
import esewa_img from '../assets/esewa.png'
import fonepay_img from '../assets/fonepay.png'
import food_quality_img from '../assets/food_quality.svg'
import preparing from '../assets/preparing.gif'
import preparing_large from '../assets/preparing_large.gif'
import service_img from '../assets/service.svg'
import social_facebook from '../assets/social_facebook.svg'
import social_insta from '../assets/social_insta.svg'
import social_tiktok from '../assets/social_tiktok.svg'

export const spiceDisplayLabel = [
    {
        value: 0,
        label: '',
    },
    {
        value: 33,
        label: '',
    },
    {
        value: 66,
        label: '',
    },
    {
        value: 100,
        label: '',
    },
];

export const OrderStatusType = {
    NEW: "new",
    PREPARING: "preparing",
    COOKING: "cooking",
    COMPLETED: "completed",
}

export const CustomerActionType = {
    NOTIFY: "notify",
    READY_TO_PAY: "ready_to_pay",
}

export const preparingGIF = preparing
export const preparing_largeGIF = preparing_large
export const cookingGIF = cooking
export const cooking_largeGIF = cooking_large

// payment info
export const esewa = esewa_img
export const fonepay = fonepay_img

// social media icons
export const tiktok_icon = social_tiktok
export const insta_icon = social_insta
export const facebook_icon = social_facebook

// feedback
export const food_quality = food_quality_img
export const beverage_quality = beverage_quality_img
export const atmosphere = atmosphere_img
export const service = service_img

export const FEEDBACK_FACTORS =
{
    food_quality: "food_quality",
    beverage_quality: "beverage_quality",
    atmosphere: "atmosphere",
    service: "service"
}

// dummy data
export const categoriesWithItems =
    [
        {
            "id": "3f3de2d3-57a1-47dc-aad0-22e47d5e11a2",
            "name": "fgfgfgfggf",
            "color": "#187855",
            "shape": "circle",
            "items": [
                {
                    "id": "d3372eed-db03-42be-a7e6-bb0d946adf3c",
                    "name": "fgdfd1",
                    "image": null,
                    "color": "#187855",
                    "shape": "circle",
                    "sellingType": "weight",
                    "trackStock": true,
                    "hasSpiceLevel": true,
                    "hasVariants": true,
                    "variants": [
                        {
                            "id": "63ba85f7-6b7a-417b-a056-961416a31f62",
                            "name": "dfdf",
                            "description": null,
                            "price": 10,
                            "hasFixedPrice": true,
                            "inStock": 10,
                            "isVeg": false,
                            "discountAmount": 0,
                            "discountedPrice": 10
                        }
                    ],
                    "discount": null,
                    "modifiers": [
                        {
                            "id": "7e0b1fd9-2897-4dfb-af43-cb4f2bd79aa3",
                            "name": "Toppings",
                            "options": [
                                {
                                    "id": "a1053026-0da7-4660-a42d-a2f0eff5c312",
                                    "name": "peporine",
                                    "price": 100,
                                    "hasFixedPrice": true
                                },
                                {
                                    "id": "63b497ef-1d8e-447b-9e89-429fba4c98dd",
                                    "name": "olive",
                                    "price": 200,
                                    "hasFixedPrice": true
                                }
                            ]
                        }
                    ]
                },
                {
                    "id": "3cdb65ac-474a-4816-91fa-d4aeccdeb7e6",
                    "name": "sdSDSDAsad",
                    "image": null,
                    "color": "#ECAA00",
                    "shape": "square",
                    "sellingType": "weight",
                    "trackStock": true,
                    "hasSpiceLevel": true,
                    "hasVariants": false,
                    "variants": [
                        {
                            "id": "3cdb65ac-474a-4816-91fa-d4aeccdeb7e6",
                            "name": "sdSDSDAsad",
                            "description": null,
                            "price": 222,
                            "hasFixedPrice": true,
                            "inStock": 0,
                            "isVeg": true,
                            "discountAmount": 0,
                            "discountedPrice": 222
                        }
                    ],
                    "discount": null,
                    "modifiers": [
                        {
                            "id": "7e0b1fd9-2897-4dfb-af43-cb4f2bd79aa3",
                            "name": "Toppings",
                            "options": [
                                {
                                    "id": "a1053026-0da7-4660-a42d-a2f0eff5c312",
                                    "name": "peporine",
                                    "price": 100,
                                    "hasFixedPrice": true
                                },
                                {
                                    "id": "63b497ef-1d8e-447b-9e89-429fba4c98dd",
                                    "name": "olive",
                                    "price": 200,
                                    "hasFixedPrice": true
                                }
                            ]
                        },
                        {
                            "id": "1868f099-e6a8-469b-8fa9-aca6bf99837d",
                            "name": "Addons",
                            "options": [
                                {
                                    "id": "76c43e0f-4aa5-4665-a8d3-1350d384ea7e",
                                    "name": "sitan",
                                    "price": 20,
                                    "hasFixedPrice": true
                                }
                            ]
                        },
                        {
                            "id": "6328d4bc-0d14-4c23-8879-bc26deaef6dd",
                            "name": "sitan",
                            "options": [
                                {
                                    "id": "5e4c799d-e11f-4aef-ad31-187916b2bb6c",
                                    "name": "bhutan",
                                    "price": 33,
                                    "hasFixedPrice": true
                                }
                            ]
                        },
                        {
                            "id": "47d8b836-eeaf-402f-b8e4-44180d100c25",
                            "name": "tetst2",
                            "options": [
                                {
                                    "id": "8f3e7574-8dd1-4ed6-a173-bf435196992d",
                                    "name": "test2",
                                    "price": 29,
                                    "hasFixedPrice": true
                                },
                                {
                                    "id": "0eb4be3a-7ae9-4a22-9b3f-ed19005a5b49",
                                    "name": "test5",
                                    "price": 222,
                                    "hasFixedPrice": true
                                },
                                {
                                    "id": "45eb4993-24f8-405d-9ada-3b2a84508d2b",
                                    "name": "dffdssfd",
                                    "price": 329,
                                    "hasFixedPrice": true
                                }
                            ]
                        }
                    ]
                },
                {
                    "id": "f7ce0435-fe2d-4a90-8942-2bd10a05b2a9",
                    "name": "Mallory Ellison",
                    "image": null,
                    "color": "#ECAA00",
                    "shape": "square",
                    "sellingType": "weight",
                    "trackStock": true,
                    "hasSpiceLevel": true,
                    "hasVariants": true,
                    "variants": [
                        {
                            "id": "4e67f0a5-a33d-45f9-8423-434b454a1f19",
                            "name": "chicken/garbe",
                            "description": null,
                            "price": 10,
                            "hasFixedPrice": true,
                            "inStock": 10,
                            "isVeg": false,
                            "discountAmount": 0,
                            "discountedPrice": 10
                        },
                        {
                            "id": "ce87b38d-b16b-47f8-b281-74c40f3d60ca",
                            "name": "buff/garbe",
                            "description": null,
                            "price": 11,
                            "hasFixedPrice": true,
                            "inStock": 22,
                            "isVeg": false,
                            "discountAmount": 0,
                            "discountedPrice": 11
                        },
                        {
                            "id": "23151210-120b-458e-b639-c739d55c57d6",
                            "name": "buff/achar",
                            "description": null,
                            "price": 0,
                            "hasFixedPrice": false,
                            "inStock": 22,
                            "isVeg": false,
                            "discountAmount": 0,
                            "discountedPrice": 0
                        }
                    ],
                    "discount": null,
                    "modifiers": [
                        {
                            "id": "7e0b1fd9-2897-4dfb-af43-cb4f2bd79aa3",
                            "name": "Toppings",
                            "options": [
                                {
                                    "id": "a1053026-0da7-4660-a42d-a2f0eff5c312",
                                    "name": "peporine",
                                    "price": 100,
                                    "hasFixedPrice": true
                                },
                                {
                                    "id": "63b497ef-1d8e-447b-9e89-429fba4c98dd",
                                    "name": "olive",
                                    "price": 200,
                                    "hasFixedPrice": true
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "id": "b24e22b5-b0ef-49b6-9524-55306ef8535d",
            "name": "dessert",
            "color": "#EF476F",
            "shape": "square",
            "items": [
                {
                    "id": "d3d437b6-e43f-4060-9965-3829cb55b661",
                    "name": "waduhek",
                    "image": "https://fantasticpos.s3.ap-south-1.amazonaws.com/items/1689841699309-62576160-bd20-4035-95bb-ff952c82195c.png",
                    "color": null,
                    "shape": null,
                    "sellingType": "each",
                    "trackStock": false,
                    "hasSpiceLevel": true,
                    "hasVariants": true,
                    "variants": [
                        {
                            "id": "863c72d3-1b2f-4d6b-b383-255a67e2ae1a",
                            "name": "achar/veg",
                            "description": null,
                            "price": 123,
                            "hasFixedPrice": true,
                            "inStock": 0,
                            "isVeg": false,
                            "discountAmount": 0,
                            "discountedPrice": 123
                        },
                        {
                            "id": "e980322d-272c-482c-b7bc-1ee76a2699f4",
                            "name": "achar/chicken",
                            "description": null,
                            "price": 213,
                            "hasFixedPrice": true,
                            "inStock": 0,
                            "isVeg": false,
                            "discountAmount": 0,
                            "discountedPrice": 213
                        },
                        {
                            "id": "08723321-8473-4e66-a7bc-c333fd258362",
                            "name": "achar/buff",
                            "description": null,
                            "price": 12,
                            "hasFixedPrice": true,
                            "inStock": 0,
                            "isVeg": false,
                            "discountAmount": 0,
                            "discountedPrice": 12
                        },
                        {
                            "id": "ec1ddfe9-dde9-4237-a548-b1f4627eb412",
                            "name": "gravy/veg",
                            "description": null,
                            "price": 43,
                            "hasFixedPrice": true,
                            "inStock": 0,
                            "isVeg": false,
                            "discountAmount": 0,
                            "discountedPrice": 43
                        },
                        {
                            "id": "b4aadbef-b484-4b5a-8381-089b8357d95d",
                            "name": "gravy/chicken",
                            "description": null,
                            "price": 1234,
                            "hasFixedPrice": true,
                            "inStock": 0,
                            "isVeg": false,
                            "discountAmount": 0,
                            "discountedPrice": 1234
                        },
                        {
                            "id": "2e78a07c-00ac-44f8-bb08-f4d04a327efc",
                            "name": "gravy/buff",
                            "description": null,
                            "price": 3424,
                            "hasFixedPrice": true,
                            "inStock": 0,
                            "isVeg": false,
                            "discountAmount": 0,
                            "discountedPrice": 3424
                        }
                    ],
                    "discount": null,
                    "modifiers": [
                        {
                            "id": "7e0b1fd9-2897-4dfb-af43-cb4f2bd79aa3",
                            "name": "Toppings",
                            "options": [
                                {
                                    "id": "a1053026-0da7-4660-a42d-a2f0eff5c312",
                                    "name": "peporine",
                                    "price": 100,
                                    "hasFixedPrice": true
                                },
                                {
                                    "id": "63b497ef-1d8e-447b-9e89-429fba4c98dd",
                                    "name": "olive",
                                    "price": 200,
                                    "hasFixedPrice": true
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "id": "eb9a663a-5093-4460-b06b-34fb5bcf0e28",
            "name": "test3",
            "color": "#187855",
            "shape": "circle",
            "items": null
        },
        {
            "id": "13095f40-6873-4c87-8772-af0d2297a3e9",
            "name": "test5",
            "color": "#ECAA00",
            "shape": "circle",
            "items": null
        },
        {
            "id": "74ca76ca-c7fd-4723-a3f0-3ef8db454ba4",
            "name": "test6",
            "color": "#214E6D",
            "shape": "square",
            "items": [
                {
                    "id": "f148e2f5-ac90-4d5a-89a7-3de0b9609e8f",
                    "name": "DSdsdsa",
                    "image": "https://fantasticpos.s3.ap-south-1.amazonaws.com/items/1689841699309-62576160-bd20-4035-95bb-ff952c82195c.png",
                    "color": null,
                    "shape": null,
                    "sellingType": "weight",
                    "trackStock": true,
                    "hasSpiceLevel": true,
                    "hasVariants": false,
                    "variants": [
                        {
                            "id": "f148e2f5-ac90-4d5a-89a7-3de0b9609e8f",
                            "name": "DSdsdsa",
                            "description": null,
                            "price": 100,
                            "hasFixedPrice": true,
                            "inStock": 100,
                            "isVeg": true,
                            "discountAmount": 10,
                            "discountedPrice": 90
                        }
                    ],
                    "discount": {
                        "id": "c451b39a-4f1d-4af5-a2b5-872afec403c1",
                        "name": "waduhek discount",
                        "type": "percent",
                        "value": 10
                    },
                    "modifiers": [
                        {
                            "id": "7e0b1fd9-2897-4dfb-af43-cb4f2bd79aa3",
                            "name": "Toppings",
                            "options": [
                                {
                                    "id": "a1053026-0da7-4660-a42d-a2f0eff5c312",
                                    "name": "peporine",
                                    "price": 100,
                                    "hasFixedPrice": true
                                },
                                {
                                    "id": "63b497ef-1d8e-447b-9e89-429fba4c98dd",
                                    "name": "olive",
                                    "price": 200,
                                    "hasFixedPrice": true
                                }
                            ]
                        },
                        {
                            "id": "ea29ce69-87c6-42f5-ae1c-e2ddfaaa389c",
                            "name": "test",
                            "options": [
                                {
                                    "id": "96e23b81-7925-499b-ad83-57a0fd3270ff",
                                    "name": "test",
                                    "price": 2,
                                    "hasFixedPrice": true
                                }
                            ]
                        },
                        {
                            "id": "47d8b836-eeaf-402f-b8e4-44180d100c25",
                            "name": "tetst2",
                            "options": [
                                {
                                    "id": "8f3e7574-8dd1-4ed6-a173-bf435196992d",
                                    "name": "test2",
                                    "price": 29,
                                    "hasFixedPrice": true
                                },
                                {
                                    "id": "0eb4be3a-7ae9-4a22-9b3f-ed19005a5b49",
                                    "name": "test5",
                                    "price": 222,
                                    "hasFixedPrice": true
                                },
                                {
                                    "id": "45eb4993-24f8-405d-9ada-3b2a84508d2b",
                                    "name": "dffdssfd",
                                    "price": 329,
                                    "hasFixedPrice": true
                                }
                            ]
                        }
                    ]
                },
                {
                    "id": "d51ce85d-8fa6-4ccd-800f-49dec645d909",
                    "name": "asdfdsgfddh",
                    "image": null,
                    "color": null,
                    "shape": null,
                    "sellingType": "weight",
                    "trackStock": true,
                    "hasSpiceLevel": true,
                    "hasVariants": false,
                    "variants": [
                        {
                            "id": "3f3de2d3-57a1-47dc-aad0-22e47d5e11a2",
                            "name": "DSdsdsa",
                            "description": "omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet",
                            "price": 100,
                            "hasFixedPrice": true,
                            "inStock": 1787,
                            "isVeg": true,
                            "discountAmount": 10,
                            "discountedPrice": 90
                        }
                    ],
                    "discount": {
                        "id": "c451b39a-4f1d-4af5-a2b5-872afec403c1",
                        "name": "waduhek discount",
                        "type": "percent",
                        "value": 10
                    },
                    "modifiers": [
                        {
                            "id": "1868f099-e6a8-469b-8fa9-aca6bf99837d",
                            "name": "Addons",
                            "options": [
                                {
                                    "id": "76c43e0f-4aa5-4665-a8d3-1350d384ea7e",
                                    "name": "sitan",
                                    "price": 20,
                                    "hasFixedPrice": true
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "id": "d68a78a0-7ee9-4059-8ed5-e04bc7e1ae10",
            "name": "test9",
            "color": "#187855",
            "shape": "square",
            "items": [
                {
                    "id": "56dfe54c-9a16-4566-b90f-1fd0d8ca4149",
                    "name": "test",
                    "image": null,
                    "color": "#214E6D",
                    "shape": "square",
                    "sellingType": "weight",
                    "trackStock": false,
                    "hasSpiceLevel": false,
                    "hasVariants": true,
                    "variants": [
                        {
                            "id": "e9df4948-d664-4d88-8072-c4fa89ff7431",
                            "name": "WW",
                            "description": null,
                            "price": 3,
                            "hasFixedPrice": true,
                            "inStock": 0,
                            "isVeg": false,
                            "discountAmount": 0,
                            "discountedPrice": 3
                        },
                        {
                            "id": "aaed74e0-483a-413e-81a0-3b33bdb57cb1",
                            "name": "WEWE",
                            "description": null,
                            "price": 22,
                            "hasFixedPrice": true,
                            "inStock": 0,
                            "isVeg": true,
                            "discountAmount": 0,
                            "discountedPrice": 22
                        }
                    ],
                    "discount": null,
                    "modifiers": [
                        {
                            "id": "7e0b1fd9-2897-4dfb-af43-cb4f2bd79aa3",
                            "name": "Toppings",
                            "options": [
                                {
                                    "id": "a1053026-0da7-4660-a42d-a2f0eff5c312",
                                    "name": "peporine",
                                    "price": 100,
                                    "hasFixedPrice": true
                                },
                                {
                                    "id": "63b497ef-1d8e-447b-9e89-429fba4c98dd",
                                    "name": "olive",
                                    "price": 200,
                                    "hasFixedPrice": true
                                }
                            ]
                        },
                        {
                            "id": "1868f099-e6a8-469b-8fa9-aca6bf99837d",
                            "name": "Addons",
                            "options": [
                                {
                                    "id": "76c43e0f-4aa5-4665-a8d3-1350d384ea7e",
                                    "name": "sitan",
                                    "price": 20,
                                    "hasFixedPrice": true
                                }
                            ]
                        },
                        {
                            "id": "6328d4bc-0d14-4c23-8879-bc26deaef6dd",
                            "name": "sitan",
                            "options": [
                                {
                                    "id": "5e4c799d-e11f-4aef-ad31-187916b2bb6c",
                                    "name": "bhutan",
                                    "price": 33,
                                    "hasFixedPrice": true
                                }
                            ]
                        },
                        {
                            "id": "ea29ce69-87c6-42f5-ae1c-e2ddfaaa389c",
                            "name": "test",
                            "options": [
                                {
                                    "id": "96e23b81-7925-499b-ad83-57a0fd3270ff",
                                    "name": "test",
                                    "price": 2,
                                    "hasFixedPrice": true
                                }
                            ]
                        },
                        {
                            "id": "47d8b836-eeaf-402f-b8e4-44180d100c25",
                            "name": "tetst2",
                            "options": [
                                {
                                    "id": "8f3e7574-8dd1-4ed6-a173-bf435196992d",
                                    "name": "test2",
                                    "price": 29,
                                    "hasFixedPrice": true
                                },
                                {
                                    "id": "0eb4be3a-7ae9-4a22-9b3f-ed19005a5b49",
                                    "name": "test5",
                                    "price": 222,
                                    "hasFixedPrice": true
                                },
                                {
                                    "id": "45eb4993-24f8-405d-9ada-3b2a84508d2b",
                                    "name": "dffdssfd",
                                    "price": 329,
                                    "hasFixedPrice": true
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]

export const taxes = [
    {
        "id": "d0308f31-6b1b-4c21-a26f-740811091e04",
        "name": "service charge",
        "type": "percent",
        "value": 10,
        "symbol": "%"
    },
    {
        "id": "fd85947a-4bc7-40f2-a32f-85e86387ca99",
        "name": "vat",
        "type": "amount",
        "value": 13,
        "symbol": "Rs."
    }
]

