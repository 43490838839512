import { Button, Container, IconButton } from '@mui/material'
import { ArrowLeft, Star } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CircularProgressNovus } from '../../common/components'
import { getBusinessId } from '../../common/cookie'
import { atmosphere, beverage_quality, food_quality, service } from '../../common/datasets'
import { selected_service, setError } from '../Layout/userLayoutSlice'
import { useCreateFeedbackMutation } from '../slices/apiSlice'

const feedback_data = [
    {
        title: 'Food Quality',
        image: food_quality,
        value: "food_quality"
    },
    {
        title: 'Beverage Quality',
        image: beverage_quality,
        value: "beverage_quality"
    },
    {
        title: 'Atmosphere',
        image: atmosphere,
        value: "atmosphere"
    },
    {
        title: 'Service',
        image: service,
        value: "service"
    },
]

function Feedback() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [stars, setStars] = useState(0)
    const [selIndex, setSelIndex] = useState([])
    const [message, setMessage] = useState('')
    const [createFeedback, { isLoading }] = useCreateFeedbackMutation()
    const selected = useSelector(selected_service)
    const handleSelectFactors = (index) => {
        if (selIndex.includes(index)) setSelIndex((prevSelIndex) => prevSelIndex.filter(item => item !== index));
        else setSelIndex((prevSelIndex) => [...prevSelIndex, index]);
    }

    const handleCreateFeedback = async () => {
        try {
            const factors = selIndex.map(index => feedback_data[index].value);
            const payload = {
                businessId: getBusinessId(),
                rating: stars,
                factors: factors,
                comment: message,
                origin: "contactless_app"
            }
            const res = await createFeedback(payload)
            if (!res.data?.success) throw res.error?.data
            dispatch(
                setError({ errorPosition: "success", errorText: "Feedback submitted" })
            );
            navigate(`/?services=${selected}`)
        } catch (error) {
            dispatch(
                setError({ errorPosition: "apiErr", errorText: error?.message })
            );
        }
    }

    return (
        <Container className="flex flex-col py-4 flex-1">

            {/* top */}
            <div className="flex items-center">
                <IconButton
                    onClick={(e) =>
                        navigate(`/?services=${selected}`, { replace: true })
                    }
                >
                    <ArrowLeft size={24} color="#2B2B2B" />
                </IconButton>
                <span className="title-semibold">Feedback</span>
            </div>
            {/* rate your visit */}
            <div className='flex flex-col gap-3 items-center mt-3'>
                <span className='title-semibold'>Rate your visit</span>
                <div className='flex justify-between'>
                    {[1, 2, 3, 4, 5].map((each, i) => (
                        <IconButton key={'feedback_star_' + i}
                            circle onClick={e => setStars(each)}
                        >
                            <Star size={32} weight={each <= stars ? 'fill' : 'regular'}
                                className={each <= stars ? 'text-warning' : 'text-dark_gray'} />
                        </IconButton>
                    ))}
                </div>
            </div>
            {/* whats impacted */}
            <div className='flex flex-col gap-4 mt-8 items-center'>
                <span className='title-semibold'>What impacted your rating?</span>
                <div className='grid grid-cols-2 w-full gap-4'>
                    {
                        feedback_data?.map((each, i) => (
                            <Button
                                onClick={e => handleSelectFactors(i)}
                                className={`flex flex-col gap-4 px-2 pt-6 pb-3 border border-solid
                             ${selIndex.includes(i) ? 'border_primary-default bg-primary_light' : 'border-primary_light'}`}>
                                <img src={each.image} alt="" />
                                <span className='subtitle text-icon_dark'>{each.title}</span>
                            </Button>
                        ))
                    }
                </div>
            </div>
            {/* question */}
            <div className='flex flex-col gap-2 mt-4'>
                <span>Would you like to add anything?</span>
                <textarea name="" id="" rows='5' className='border_light-gray body-regular focus:outline-none p-1'
                    value={message} onChange={e => setMessage(e.target.value)} />
            </div>
            {/* submit */}
            <Button className='btn-primary-large w-full mt-4' onClick={handleCreateFeedback}>
                {isLoading ? <CircularProgressNovus /> : 'Submit Feedback'}
            </Button>

        </Container>
    )
}

export default Feedback