import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../Pages/Layout/userLayoutSlice";
import { apiSlice } from "../api/apiSlice";

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        counter: counterReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(apiSlice.middleware)
})