import { apiSlice } from '../../api/apiSlice';

const baseUrl = '/api/auth'
export const customerApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        checkUser: builder.mutation({
            query: initialPost => ({
                url: `${baseUrl}/checkUser`,
                method: 'POST',
                body: {
                    ...initialPost,
                }
            }),
        }),
        sendOTP: builder.mutation({
            query: initialPost => ({
                url: `${baseUrl}/otp `,
                method: 'POST',
                body: {
                    ...initialPost,
                }
            }),
        }),
        logister: builder.mutation({
            query: initialPost => ({
                url: `${baseUrl}/logister `,
                method: 'POST',
                body: {
                    ...initialPost,
                }
            }),
        }),
    })
})

export const {
    useCheckUserMutation,
    useSendOTPMutation,
    useLogisterMutation
} = customerApiSlice