import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
// export const baseUrl = 'http://192.168.1.90:8001/'
export const baseUrl = 'https://api.airnovus.io/'
// export const baseUrl = process.env.REACT_APP_BASE_URL

export const apiSlice = createApi({
  reducerPath: 'api', // optional
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  tagTypes: ['Categories'],
  endpoints: builder => ({})
})