import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { apiSlice } from '../../api/apiSlice';
import { getBusinessId, getUserId, getUserToken } from "../../common/cookie";

const orderHistoryDataAdapter = createEntityAdapter({})
const initialOrderHistoryState = orderHistoryDataAdapter.getInitialState({});

export const customerApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        // get business info
        getBusinessInfo: builder.query({
            query: initialPost => ({
                // url: `api/api/businesses/info/general/b0bfd366-c4db-4651-adf6-d7c817b66b14`,
                url: `api/businesses/info/general/${getBusinessId()}`,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                },
            }),
        }),
        // on scan
        getTableStatus: builder.query({
            query: (body) => ({
                url: `/api/floors/tables/customer/${body.tableId}/${body.businessId}`,
            }),
        }),
        // homepage
        getHome: builder.query({
            query: (services) => ({
                url: `/api/categories/all/${getBusinessId()}?services=${services.search}`,
            }),
            transformResponse: responseData => {
                return responseData.success ? responseData.data : responseData;
            },
        }),
        // create new order
        createOrder: builder.mutation({
            query: initialPost => ({
                url: '/api/orders/create',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                },
                method: 'POST',
                body: {
                    ...initialPost,
                }
            }),
        }),
        // notify waiter
        notifyWaiter: builder.mutation({
            query: initialPost => ({
                url: `api/floors/tables/notify/${getBusinessId()}`,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                },
                method: 'POST',
                body: { ...initialPost }
            }),
        }),
        // user profile
        getPurchaseHistory: builder.query({
            query: initialPost => ({
                url: `api/users/history/${getUserId()}`,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                },
            }),
            transformResponse: responseData => {
                const entities = {
                    orders: orderHistoryDataAdapter.setAll(initialOrderHistoryState,
                        responseData ?? []),
                };
                return entities;
            },
        }),
        // edit name, email , address and image
        editUserProfile: builder.mutation({
            query: initialPost => ({
                url: '/api/users/edit',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                },
                method: 'PUT',
                body: {
                    ...initialPost,
                }
            }),
        }),
        // change user phone from edit profile - Send OTP
        changePhoneSendOTP: builder.mutation({
            query: initialPost => ({
                url: '/api/users/change/phone/otp',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                },
                method: 'POST',
                body: {
                    ...initialPost,
                }
            }),
        }),
        // change user phone from edit profile - Verify OTP
        changePhoneVerifyOTP: builder.mutation({
            query: initialPost => ({
                url: '/api/users/change/phone',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                },
                method: 'POST',
                body: {
                    ...initialPost,
                }
            }),
        }),
        // create feedback
        createFeedback: builder.mutation({
            query: initialPost => ({
                url: '/api/feedbacks',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                },
                method: 'POST',
                body: {
                    ...initialPost,
                }
            }),
        }),
    })
})

export const {
    useGetBusinessInfoQuery,
    useGetTableStatusQuery,
    useGetHomeQuery,
    useCreateOrderMutation,
    useNotifyWaiterMutation,
    useGetPurchaseHistoryQuery,
    useEditUserProfileMutation,
    useChangePhoneSendOTPMutation,
    useChangePhoneVerifyOTPMutation,
    useCreateFeedbackMutation
} = customerApiSlice

// returns the query result object
export const selectHomeResult = customerApiSlice.endpoints.getHome.select()

// for order hsitory
// returns the query result object
export const selectHistoryResult = customerApiSlice.endpoints.getPurchaseHistory.select()

// creates memoized selector
const selectOrderHistoryData = createSelector(
    selectHistoryResult,
    postsResult => postsResult.data?.orders
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllOrdersHistory,
    selectById: selectOrderHistoryById,
    selectIds: selectOrderHistoryIds
    // Pass in a selector that returns the posts slice of state
} = orderHistoryDataAdapter.getSelectors(state => selectOrderHistoryData(state) ?? initialOrderHistoryState)