import { Button, Container, Skeleton } from "@mui/material";
import { CaretRight, CheckCircle } from "@phosphor-icons/react";
import { default as React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isTextEmpty } from "../../common/common";
import { NotifyWaiterComp, ReadyForPaymentComp } from "../../common/components";
import { getUserId } from "../../common/cookie";
import { OrderStatusType, preparing_largeGIF } from "../../common/datasets";
import Login from "../Auth/Login";
import { ad_orderStatus, selected_service } from "../Layout/userLayoutSlice";

export const OrderStatus = () => {
    const orderStatus = useSelector(ad_orderStatus);
    const navigate = useNavigate();
    const selected = useSelector(selected_service)
    const [loginView, setLoginView] = useState(false); //for login before viewing check
    useEffect(() => {
        if (isTextEmpty(orderStatus) || (orderStatus==='no_orders')) {
            return navigate(`/?services=${selected}`);
        }
    }, [navigate, orderStatus, selected]);
    if (loginView)
        return (
            <Container className="flex flex-col gap-4 py-4">
                <Login />
            </Container>
        );
    else if (orderStatus === OrderStatusType.NEW || orderStatus === OrderStatusType.PREPARING)
        return <OrderProcessing setLoginView={setLoginView} />;
    else if (orderStatus === OrderStatusType.COOKING)
        return <OrderBeingPreprared setLoginView={setLoginView} />;
    else if (orderStatus === OrderStatusType.COMPLETED)
        return <OrderComplete setLoginView={setLoginView} />;
    else return null;
};

const handleOpenCheck = (navigate, setLoginView) => {
    if (!getUserId()) {
        setLoginView(true);
    } else {
        navigate("/check");
    }
};

// is processing
const OrderProcessing = (props) => {
    const navigate = useNavigate();
    const [imgLoad, setImgLoad] = useState(false);
    const selected = useSelector(selected_service)
    const { setLoginView } = props;
    return (
        <Container className="flex flex-col py-4 flex-1">
            {NotifyWaiterComp()}
            {/* image and text */}
            <div className="flex flex-col gap-8">
                <div className="mt-6 w-[60vw] aspect-square bg-no-repeat bg-center self-center">
                    <img
                        src={preparing_largeGIF}
                        className="w-0 h-0 self-center hidden"
                        alt=""
                        onLoad={(e) => setImgLoad(true)}
                    />
                    {!imgLoad ? (
                        <Skeleton variant="rectangular" className="w-full h-full" />
                    ) : (
                        <img
                            src={preparing_largeGIF}
                            className="mt-6 w-[60vw] self-center"
                            alt=""
                            onLoad={(e) => setImgLoad(true)}
                        />
                    )}
                </div>
                <div className="flex flex-col gap-[6px]">
                    <h3 className="font-semibold text-center">
                        Your order is processing
                    </h3>
                    <span className="body-regular text-center">
                        Your order is in good hands! We'll notify you when it's ready. Bon
                        appétit!
                    </span>
                </div>
            </div>
            {/* buttons */}
            <div className="flex flex-col gap-4 mt-auto">
                <Button
                    className="btn-primary-large body-semibold w-full"
                    onClick={(e) => navigate(`/?services=${selected}`)}
                >
                    Order More
                </Button>
                <Button
                    className="flex justify-between btn-secondary-large w-full"
                    onClick={(e) => handleOpenCheck(navigate, setLoginView)}
                >
                    <span>View Open Check</span>
                    <CaretRight size={24} />
                </Button>
            </div>
        </Container>
    );
};

const OrderBeingPreprared = (props) => {
    const navigate = useNavigate();
    const selected = useSelector(selected_service)
    const [imgLoad, setImgLoad] = useState(false);
    const { setLoginView } = props;

    return (
        <Container className="py-4 flex flex-col">
            {NotifyWaiterComp()}
            {/* image and text */}
            <div className="flex flex-col gap-8">
                <div className="mt-6 w-[60vw] aspect-square bg-no-repeat bg-center self-center">
                    {!imgLoad ? (
                        <Skeleton variant="rectangular" className="w-full h-full" />
                    ) : (
                        <img
                            src={preparing_largeGIF}
                            className="mt-6 w-[60vw] self-center"
                            alt=""
                            onLoad={(e) => setImgLoad(true)}
                        />
                    )}
                </div>
                <div className="flex flex-col gap-[6px]">
                    <h3 className="font-semibold text-center">
                        Your order is processing
                    </h3>
                    <span className="body-regular text-center">
                        Your order is in good hands! We'll notify you when it's ready. Bon
                        appétit!
                    </span>
                </div>
            </div>
            <div className="flex flex-col gap-4 mt-auto">
                <Button
                    className="btn-primary-large w-full"
                    onClick={(e) => navigate(`/?services=${selected}`)}
                >
                    Order More
                </Button>
                <Button
                    className="flex justify-between btn-secondary-large w-full"
                    onClick={(e) => handleOpenCheck(navigate, setLoginView)}
                >
                    <span>View Open Check</span>
                    <CaretRight size={24} />
                </Button>
            </div>
        </Container>
    );
};

const OrderComplete = (props) => {
    const navigate = useNavigate();
    const selected = useSelector(selected_service)
    const { setLoginView } = props;

    return (
        <Container className="flex py-4 flex-col gap-1">
            {NotifyWaiterComp()}
            <div className="flex flex-col h-full">
                {/* tick */}
                <div className="flex flex-col gap-2 items-center mt-auto">
                    <CheckCircle className="text-success" size={100} />
                    <h3 className="font-semibold">Your order is complete</h3>
                    <div className="title text-center">
                        Don’t forget to pay at the end of the meal
                    </div>
                </div>
                {/* buttons */}
                <div className="flex flex-col gap-4 mt-auto">
                    {ReadyForPaymentComp()}
                    <Button
                        className="btn-primary-large w-full"
                        onClick={(e) => navigate(`/?services=${selected}`)}
                    >
                        Order More
                    </Button>
                    <Button
                        className="flex justify-between btn-secondary-large w-full"
                        onClick={(e) => handleOpenCheck(navigate, setLoginView)}
                    >
                        <span>View Open Check</span>
                        <CaretRight size={24} />
                    </Button>
                </div>
            </div>
        </Container>
    );
};
