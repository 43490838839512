import { Button, Container, IconButton } from "@mui/material";
import { ArrowLeft, SquareLogo } from "@phosphor-icons/react";
import html2pdf from "html2pdf.js";
import React from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import empty_pruchase from "../../assets/empty_pruchase.svg";
import { formatDate2 } from "../../common/common";
import { getCookie } from "../../common/cookie";
import { LoadingPageGeneral } from "../ErrorPages/LoadingPage";
import {
    selectAllOrdersHistory,
    selectOrderHistoryById,
    useGetPurchaseHistoryQuery,
} from "../slices/apiSlice";
import { selected_service } from "../Layout/userLayoutSlice";

export const OrderHistoryLayout = () => {
    useGetPurchaseHistoryQuery();
    return <Outlet />;
};

function OrderHistory() {
    const navigate = useNavigate();
    const { isLoading, error } = useGetPurchaseHistoryQuery();
    const data = useSelector(selectAllOrdersHistory);
    const selected = useSelector(selected_service)
    if (isLoading) return LoadingPageGeneral();
    else if (error) return;
    return (
        <Container
            className={`flex flex-col py-4 ${data?.length === 0 && "empty-gradient"}`}
        >
            {/* top */}
            <div className="flex gap-2 items-center mb-4">
                <IconButton onClick={(e) => navigate(-1)}>
                    <ArrowLeft size={24} color="#2B2B2B" />
                </IconButton>
                <span className="title-semibold">Order History</span>
            </div>
            {/* body */}
            {data?.length === 0 ? (
                <div className="flex-1 flex flex-col justify-center items-center gap-8">
                    <img className="w-40 h-52" src={empty_pruchase} alt="" />
                    <div className="flex flex-col gap-2 items-center">
                        <span className="title-semibold">
                            You have not ordered anything
                        </span>
                        <span className="subtitle text-dark_gray">
                            Order something to view them here
                        </span>
                        <Button
                            className="btn-primary-medium"
                            onClick={(e) => navigate(`/?services=${selected}`)}
                        >
                            Go to home
                        </Button>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col gap-2">
                    {data?.map((each, i) => (
                        <div
                            key={"abc_" + i}
                            className="flex flex-col"
                            onClick={(e) => navigate(each.id)}
                        >
                            <div className="flex flex-col">
                                <div className="flex flex-col p-3 gap-1 rounded-xl border_light-gray">
                                    {/* from and date */}
                                    <div className="flex justify-between items-center caption text-dark_gray">
                                        <span>#{each.referenceNumber}</span>
                                        <span>{formatDate2(each.createdAt)}</span>
                                    </div>
                                    {/* items name */}
                                    <span className="line-clamp-2 caption">
                                        {each.itemNames?.join(",")}
                                    </span>
                                    {/* from */}
                                    <div className="flex gap-1 caption">
                                        <span>from</span>
                                        <span className="font-semibold text-primary capitalize">
                                            {each.business?.name}
                                        </span>
                                    </div>
                                    {/* price */}
                                   <div className="flex justify-between items-center">
                                   <span className="subtitle-semibold">
                                        Rs. {parseFloat(each.total).toFixed(2)}
                                    </span>
                                    {
                                        each.status==='cancelled' &&
                                        <Button className="border_primary-default border-red-400 text-red-400 py-1 px-2">Cancelled</Button>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </Container>
    );
}

export default OrderHistory;

export const OneOrderHistory = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const businessName = getCookie()?.info?.businessInfo?.name ?? "Mc Donalds";
    const logo = getCookie()?.info?.businessInfo?.logo;
    const order = useSelector((state) => selectOrderHistoryById(state, id));
    const generatePdf = async () => {
        const element = document.getElementById("receipt");
        element.style.visibility = "visible";
        await html2pdf(element).then((x) => (element.style.visibility = "hidden"));
    };
    if (!order) return;
    return (
        <Container className="flex flex-col py-4">
            {/* top */}
            <div className="flex gap-2 items-center mb-4 justify-between">
                {/* title */}
                <div className="flex gap-[6px] items-center">
                    <IconButton onClick={(e) => navigate(-1)}>
                        <ArrowLeft size={24} color="#2B2B2B" />
                    </IconButton>
                    <span className="title-semibold">#{order.referenceNumber}</span>
                </div>
                {/* download recepit */}
                <Button className="subtitle-semibold text-link" onClick={generatePdf}>
                    Download Receipt
                </Button>
            </div>
            {
                order.status==='cancelled' &&
                    <Button className="border_primary-default w-fit ml-auto mb-2 border-red-400 text-red-400 py-1 px-2">Cancelled</Button>
            }
            {/* details div */}
            <div className="flex flex-col p-3 gap-3 border_light-gray rounded-xl">
                {/* from and time */}
                <div className="flex justify-between items-center caption text-dark_gray">
                    <div className="flex gap-1">
                        <span>from</span>
                        <span className="font-semibold text-primary capitalize">
                            {order.business?.name}
                        </span>
                    </div>
                    <span>{formatDate2(order.createdAt)}</span>
                </div>
                {/* paid through */}
                {/* <div className="flex justify-between items-center subtitle">
                    <span>Paid through</span>
                    <span className="capitalize body-semibold items-center flex gap-2">
                        <img
                            className="max-w-[50px]"
                            src={getPaymentImage(order.transactions?.[0]?.origin)}
                            alt=""
                        />
                        <span>{order.transactions?.[0]?.origin}</span>
                    </span>
                </div> */}
            </div>
            {/* order list */}
            <div className="flex flex-col gap-3 mt-4">
                <span className="subtitle text-dark_gray">Order List</span>
                <div className="flex flex-col gap-2">
                    {order.ordersItems?.map((each, i) => (
                        <div
                            key={"order_items_" + i}
                            className="flex flex-col gap-1 pb-3 border-0 border-solid border-b-[1px] border-almost_white"
                        >
                            {/* item details */}
                            <div className="flex flex-row justify-between">
                                {/* name */}
                                <div className="flex">
                                    {/* veg sign */}
                                    <div className="flex flex-grow">
                                        {each.isVeg && (
                                            <SquareLogo
                                                color="#33D29C"
                                                weight="fill"
                                                className="title-semibold"
                                            />
                                        )} 
                                        {(each.isVeg===false) &&(
                                            <SquareLogo
                                                color="#E22B41"
                                                weight="fill"
                                                className="title-semibold"
                                            />
                                        )}
                                    </div>
                                    {/* name */}
                                    <div className="flex flex-wrap subtitle-semibold text-primary">
                                        <span className="ml-1 capitalize">{each.itemName}</span>
                                        <span className="ml-1 capitalize">
                                            {each.hasVariants && each.variantName}
                                        </span>
                                        <span className="ml-1">x{each.quantity}</span>
                                    </div>
                                </div>
                                {/* price */}
                                <div className="body-regular flex min-w-fit">
                                    Rs. {each.price}
                                </div>
                            </div>
                            {/* modifiers */}
                            <div className="flex justify-between pl-4">
                                {/* modifiers */}
                                <div className="flex flex-col gap-1 caption text-dark_gray">
                                    {each.modifiers?.map((eachMod, indMod) => (
                                        <div
                                            key={"review_item_mod_" + i + indMod}
                                            className="flex flex-col gap-1"
                                        >
                                            {eachMod.options?.map((eachOp, indOp) => (
                                                <div
                                                    key={"review_item_mod_option+" + i + indMod + indOp}
                                                    className={`flex justify-between`}
                                                >
                                                    <div className="flex gap-1">
                                                        + {eachOp.name}
                                                        {eachOp.quantity > 1 && (
                                                            <span>x{eachOp.quantity}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                    {/* spice level */}
                                    <div className="flex gap-1 justify-between subtitle text-dark_gray">
                                        <span>+ Spice Level - </span>
                                        <span>{each.spiceLevel}</span>
                                    </div>
                                </div>
                            </div>
                            {/* note */}
                            <span className="text-dark_gray italic caption">{each.note}</span>
                        </div>
                    ))}
                </div>
            </div>
            {/* subtotal */}
            <div className="flex flex-col gap-2 subtitle mt-2">
                <div className="flex justify-between text-dark_gray">
                    <span>Subtotal</span>
                    <span>Rs. {parseFloat(order.subTotal).toFixed(2)}</span>
                </div>
                {order.discountsTotal > 0 && (
                    <div className="flex justify-between text-dark_gray">
                        <span>Discount</span>
                        <span className="text-error">
                            Rs. {parseFloat(order.discountsTotal).toFixed(2)}
                        </span>
                    </div>
                )}
                {order.taxTotal && (
                    <div className="flex justify-between body-regular text-dark_gray">
                        <span>Tax</span>
                        <span>Rs. {parseFloat(order.taxTotal).toFixed(2)}</span>
                    </div>
                )}
                <div className="flex justify-between">
                    <span>Total</span>
                    <span>Rs. {parseFloat(order.total).toFixed(2)}</span>
                </div>
            </div>

            {/* receipt */}
            <div
                className="flex flex-col p-[5vw]"
                id="receipt"
                style={{
                    fontFamily: "Arial",
                    visibility: "hidden",
                }}
            >
                {/* top */}
                <div className="flex justify-between pb-4">
                    <div className="flex flex-col gap-1">
                        <img src={logo} alt="" style={{ width: 36, height: 36 }} />
                        <span className="text-base font-bold">{businessName}</span>
                    </div>
                    <span>#{order.serialNumber}</span>
                </div>
                {/* table */}
                <div className="flex flex-col top-5">
                    {/* heading */}
                    <div className="grid grid-cols-5 py-2 px-4 justify-between bg-almost_white">
                        <div className="col-span-3">Description</div>
                        <div className="col-span-1">Qty</div>
                        <div className="col-span-1">Price</div>
                    </div>
                    {/* content */}
                    {order.items?.map((each, i) => (
                        <div className="grid grid-cols-5 p-4 text-[0.9rem]">
                            {/* description */}
                            <div className="col-span-3 flex flex-col gap-2">
                                {/* name */}
                                <div className="flex flex-col">
                                    {each.itemName}{" "}
                                    {each.hasVariants && each.variantName}
                                </div>
                                {/* modifiers */}
                                <div className="flex flex-col gap-1 pl-4">
                                    {each.modifiers?.map((eachMod, indMod) => (
                                        <div
                                            key={"review_item_mod_" + indMod}
                                            className="flex flex-col gap-1"
                                        >
                                            {eachMod.options?.map((eachOp, indOp) => (
                                                <div
                                                    key={"review_item_mod_option+" + indMod + indOp}
                                                    className={`flex justify-between`}
                                                >
                                                    <div className="flex gap-1">
                                                        {eachOp.name}
                                                        {eachOp.quantity > 1 && (
                                                            <span>x{eachOp.quantity}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                    {/* spice level */}
                                    {each.hasSpiceLevel && (
                                        <div className="flex gap-1">
                                            <span>Spice Level -</span>
                                            <span>{each.spiceLevel}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* qty */}
                            <div className="col-span-1">{order.quantity}</div>
                            {/* price */}
                            <div className="col-span-1">Rs.{order.price}</div>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    );
};
