import { Container, ThemeProvider, createTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useSearchParams } from "react-router-dom";
import laptop from "../../assets/laptop.svg";
import { AlertSnack } from "../../common/components";
import { getBusinessId, getTableId } from "../../common/cookie";
import { CustomerActionType } from "../../common/datasets";
import NotifyWaiter from "./NotifyWaiter";
import {
    ad_errorPosition,
    ad_errorText,
    ad_notifyWaiter,
    ad_notifyWaiterAction,
    ad_orderStatus,
    setCheckContent,
    setOpenNotifyWaiter,
    setOrderStatus,
    setSelectedService,
    socketObj
} from "./userLayoutSlice";

// theme for mui components
const customTheme = createTheme({
    typography: {
        fontFamily: "Outfit",
        fontWeightRegular: 400,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    minWidth: "10px",
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    minWidth: "10px",
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    "& .MuiSvgIcon-root": {
                        height: 15,
                        width: 15,
                    },
                },
            },
        },
    },
});

export const Layout = () => {
    const dispatch = useDispatch();
    const viewportHeight = window.innerHeight;
    const openNotifyWaiter = useSelector(ad_notifyWaiter);
    const notifyWaiterAction = useSelector(ad_notifyWaiterAction);
    const errorPosition = useSelector(ad_errorPosition);
    const errorText = useSelector(ad_errorText);
    const orderStatus = useSelector(ad_orderStatus);
    const socket = useSelector(socketObj)
    const [searchParams] = useSearchParams()
    useEffect(() => { }, [orderStatus]);
    if (socket) socket.on('disconnect', () => {
        socket.emit('disconnection')
        socket.disconnect()
    });
    //   for socket
     useEffect(() => {
        socket?.emit("joinATable", { tableId: getTableId() });
        socket?.emit("tableOrderStatus", { tableId: getTableId() });
        socket?.on("tableOrdersStatusEmit", (message) => {
            dispatch(setOrderStatus(message));
        });
        socket?.emit("customerCheck", { tableId: getTableId(), businessId: getBusinessId() });
        socket?.on("customerCheckEmit", (message) => {
            dispatch(setCheckContent(message));
        });
    }, [dispatch, socket]);
    
    useEffect(()=>{
        if(searchParams?.get('services'))
            dispatch(setSelectedService(searchParams?.get('services')))
    },[searchParams, dispatch])

    //   render
    return (
        <ThemeProvider theme={customTheme}>
            {AlertSnack(errorPosition, errorText)}
            {/* for smaller devices */}
            <div
                style={{
                    minHeight: viewportHeight,
                }}
                className={`sm:hidden ${openNotifyWaiter ? "overflow-hidden flex-1" : "flex flex-1 w-full"
                    }`}
            >
                <Outlet />
                {openNotifyWaiter && <NotifyWaiter action={notifyWaiterAction} />}
            </div>
            {/* for md devices */}
            <Container className="xs:hidden sm:flex flex-col w-screen h-screen items-center justify-center gap-11">
                <img src={laptop} alt="" className="w-[560px]" />
                <div className="flex flex-col gap-2 items-center mb-5">
                    <span className="text-3xl font-semibold text-center">
                        Oops, looks like this site is camera-shy on desktops!
                    </span>
                    <span className="text-lg text-dark_gray text-center">
                        Try visiting from a mobile device for the full VIP experience!
                    </span>
                </div>
            </Container>
        </ThemeProvider>
    );
};

export const handleOpenNotifyWaiter = (dispatch) => {
    dispatch(
        setOpenNotifyWaiter({ open: true, openAction: CustomerActionType.NOTIFY })
    );
};

export const handleOpenNotifyWaiterPay = (dispatch) => {
    dispatch(
        setOpenNotifyWaiter({
            open: true,
            openAction: CustomerActionType.READY_TO_PAY,
        })
    );
};

export const handleCloseNotifyWaiter = (dispatch, navigate, selected) => {
    dispatch(setOpenNotifyWaiter({ open: false, openAction: null }));
    if (window.location.pathname === "/check" && navigate) navigate(`/?services=${selected}`);
};
