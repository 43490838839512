import { Avatar, Button, Container, IconButton } from "@mui/material";
import { ArrowLeft } from "@phosphor-icons/react";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InputComp, LabelComp } from "../../common/common";
import { CircularProgressNovus } from "../../common/components";
import { getUser, getUserId, updateUser } from "../../common/cookie";
import { setError } from "../Layout/userLayoutSlice";
import { useEditUserProfileMutation } from "../slices/apiSlice";

function EditUserProfile() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [editUserProfile, { isLoading }] = useEditUserProfileMutation();
    const [values, setValues] = useState({
        image: getUser()?.image ?? null,
        name: getUser()?.name ?? "",
        address: getUser()?.tempAddress ?? "",
        email: getUser()?.email ?? "",
        errorPosition: "",
        errorText: "",
    });
    const { image, name, address, email, errorPosition, errorText } = values;
    // image selector
    const fileInputRef = useRef(null);
    const handleButtonClick = () => {
        fileInputRef.current.click();
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Data = reader.result.split(",")[1];
                const base64URL = `data:${file.type};base64,${base64Data}`;
                setValues((x) => ({ ...x, image: base64URL }));
            };
            reader.readAsDataURL(file);
        }
    };
    //   update profile
    const handleSubmit = async () => {
        try {
            const res = await editUserProfile({
                id: getUserId(),
                name,
                tempAddress: address,
                image: image,
                email,
            }).unwrap();
            dispatch(
                setError({
                    errorPosition: "success",
                    errorText: "User updated",
                })
            );
            updateUser(name, address, res.data?.user?.image);
            navigate("/profile");
        } catch (error) {
            dispatch(
                setError({
                    errorPosition: "apiErr",
                    errorText: error?.data?.message,
                })
            );
        }
    };

    return (
        <Container className="flex flex-col py-4">
            {/* top */}
            <div className="flex gap-2 items-center">
                <IconButton onClick={(e) => navigate(-1)}>
                    <ArrowLeft size={24} color="#2B2B2B" />
                </IconButton>
                <span className="title-semibold">Edit Profile</span>
            </div>
            {/* form */}
            <div className="flex flex-col gap-6">
                {/* inputs*/}
                <div className="flex flex-col gap-4 mt-4">
                    {/* image */}
                    <div className="flex flex-col gap-2 self-center items-center">
                        <Avatar src={image} sx={{ width: 100, height: 100 }}>
                            <span className="text-3xl font-semibold">S</span>
                        </Avatar>
                        <div>
                            <input
                                type="file"
                                style={{ display: "none" }}
                                ref={fileInputRef}
                                onChange={handleFileChange}
                            />
                            <Button
                                onClick={handleButtonClick}
                                className="body-regular text-link"
                            >
                                Upload Image
                            </Button>
                        </div>
                    </div>
                    {/* name */}
                    <div className="flex flex-col gap-2">
                        {LabelComp("Name", "name", errorPosition, errorText)}
                        {InputComp(
                            name,
                            "name",
                            setValues,
                            "rounded-xl border_light-gray h-11 body-regular"
                        )}
                    </div>
                    {/* address */}
                    <div className="flex flex-col gap-2">
                        {LabelComp("Address", "address", errorPosition, errorText)}
                        {InputComp(
                            address,
                            "address",
                            setValues,
                            "rounded-xl border_light-gray h-11 body-regular"
                        )}
                    </div>
                    {/* email */}
                    <div className="flex flex-col gap-2">
                        {LabelComp("Email", "email", errorPosition, errorText)}
                        {InputComp(
                            email,
                            "email",
                            setValues,
                            "rounded-xl border_light-gray h-11 body-regular"
                        )}
                    </div>
                </div>
                {/* button */}
                <Button
                    className="btn-primary-large w-full body-semibold"
                    onClick={handleSubmit}
                >
                    {isLoading ? <CircularProgressNovus size={22} /> : "Update Profile"}
                </Button>
            </div>
        </Container>
    );
}

export default EditUserProfile;
