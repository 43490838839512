import { Avatar, Button, Container, Fab } from "@mui/material";
import { CallBell, MagnifyingGlass, PencilSimple, SquareLogo, UserCircle, X } from "@phosphor-icons/react";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import search_empty from "../../assets/search_empty.svg";
import { handleClickUser, isTextEmpty } from "../../common/common";
import FiltervegDrawer, { SocialMediaComp } from "../../common/components";
import { getCookie, getUser, getUserId } from "../../common/cookie";
import {
  OrderStatusType,
  cookingGIF,
  preparingGIF,
} from "../../common/datasets";
import LoadingPage from "../ErrorPages/LoadingPage";
import { handleOpenNotifyWaiter } from "../Layout/Layout";
import {
  ad_businessId,
  ad_orderStatus,
  ad_socialMedia,
  setEnableSocket,
  setTaxes,
  user_cart
} from "../Layout/userLayoutSlice";
import {
  useGetHomeQuery
} from "../slices/apiSlice";
import { CartModal } from "./AddToCartModal";
import { ReviewOrderButton } from "./ReviewOrder";
import { VariantsItemDrawer } from "./VariantsItemPage";

function Homepage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const businessName = getCookie()?.info?.businessInfo?.name;
  const logo = getCookie()?.info?.businessInfo?.logo;
  const tableName = getCookie()?.info?.tableInfo?.name;
  const userCart = useSelector(user_cart);
  const orderStatus = useSelector(ad_orderStatus);
  const { isLoading, error, data : homeData } = useGetHomeQuery({
    search : searchParams?.get('services') ?? ''
  });
  const data = useMemo(() => homeData?.categoriesWithItems ?? [], [homeData]);
  const taxesData = useMemo(() => homeData?.taxes ?? [], [homeData]);
  dispatch(setEnableSocket());

  //   appbarRef
  const [values, setValues] = useState({
    variantItemSel: null,
    bottomDrawerVariant: false,
    searchText: "",
    tabValue: 0,
    openModal: false,
    modalItem: null,
    modalData: {
      name: "",
      itemId: "",
      variantId: "",
      quantity: 1,
      price: null,
      spiceLevel: "Low",
      note: "",
      selectedModifiers: [],
    },
  });
  const {
    variantItemSel,
    bottomDrawerVariant,
    searchText,
    tabValue,
    openModal,
    modalItem,
    modalData,
  } = values;

  const [categories, setCategories] = useState(
    homeData?.categoriesWithItems ?? []
  );
  // for filter veg nonveg
  const [state, setState] = useState({
    bottom: false,
    showveg: false,
    showNonVeg: false,
  });
  const [pageOnTop, setScrollPosition] = useState(true);
  const [changeAppbar, setChangeAppbar] = useState(false);
  const [showCookingStatus, setShowCookingStatus] = useState(false);
  // const [vegFilter, setVegFilter] = useState(false);
  const businessId = useSelector(ad_businessId);
  const socialMedia = useSelector(ad_socialMedia);

  // SCROLL POSITION 
  const [tabBodyPositions, setTabBodyPositions] = useState([])
  const [tabScrollActive, setTabScrollActive] = useState(false)

  useEffect(() => { }, [userCart]);
  //   set taxes
  useEffect(() => {
    taxesData?.length > 0 && dispatch(setTaxes({ taxes: taxesData }));
  }, [taxesData, dispatch]);
  //   set initial category data
  useEffect(() => {
    const appbar = document.getElementById("novus-appbar");
    const searchAndTabs = document.getElementById("searchAndTabs");
    if (searchAndTabs && appbar) {
      const appBarHeight = appbar.clientHeight;
      searchAndTabs.style.top = `${appBarHeight}px`;
    }
    if (!businessId) navigate("404");
    setCategories(data);
  }, [data, navigate, businessId]);
  //   handle scroll
  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      setScrollPosition(currentPosition === 0);
      setChangeAppbar(currentPosition > 20);
      setShowCookingStatus(currentPosition > 199);
    };
    // Add the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // get initial scroll position for tab index change while scrolling to that position
  useEffect(() => {
    const positions = []
    var tab = null
    categories.forEach((element, i) => {
      tab = document.getElementById("home_tabs_body_each_tabPanel_" + i)
      if (element.items?.length > 0)
        positions.push(tab?.offsetTop)
    });
    setTabBodyPositions(positions)
  }, [categories])
  function findNearestScrollPositionIndex(scrollPositions, currentPosition) {
    let nearestIndex = -1;
    let minDifference = Infinity;

    for (let i = 0; i < scrollPositions.length; i++) {
      const difference = Math.abs(currentPosition - scrollPositions[i]);

      if (difference < minDifference) {
        minDifference = difference;
        nearestIndex = i;
      }
    }

    return nearestIndex;
  }
  // when scrolled, change tab index accordingly
  useEffect(() => {
    const handleScroll = () => {
      if (tabScrollActive) return
      const currentPosition = window.scrollY;
      const nearestPositionIndex = findNearestScrollPositionIndex(tabBodyPositions, currentPosition);
      if (nearestPositionIndex !== tabValue)
        setValues((x) => ({ ...x, tabValue: nearestPositionIndex }));
      const tabNow = document.getElementById('home_tabs_each_tab_' + nearestPositionIndex)
      const tabsDiv = document.getElementById('home-tabs-body')
      if (tabsDiv) tabsDiv.scrollLeft = tabNow?.offsetLeft
    };
    // Add the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [tabBodyPositions, tabValue, tabScrollActive]);

  const handleSearch = (e) => {
    setValues((x) => ({
      ...x,
      searchText: e.target.value,
    }));
    const results = searchMenuItems(e.target.value, data);
    setCategories(results);
  };
  const handleChangeTabs = (event, newValue) => {
    setTabScrollActive(true)
    setValues((x) => ({ ...x, tabValue: newValue }));
    var targetDiv = document.getElementById("tabPanel" + newValue);
    var headerOffset = 165;
    var elementPosition = targetDiv?.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    targetDiv &&
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    setTimeout(() => {
      setTabScrollActive(false)
    }, 1000);
  };
  const handleItemClick = (item) => {
    if (!item.hasVariants) {
      // if (item.variants?.[0]?.inStock > 0)
      setValues((x) => ({
        ...x,
        openModal: true,
        modalItem: item,
        modalData: {
          name: item.name,
          variantName: item.variants?.[0]?.name,
          hasVariant: false,
          itemId: item.id,
          variantId: item.variants?.[0]?.id,
          isVeg: item.variants?.[0]?.isVeg,
          quantity: 1,
          totalSelModPrice: 0,
          price: item.variants?.[0]?.price,
          discountedPrice: item.variants?.[0]?.discountedPrice,
          discountAmount: item.variants?.[0]?.discountAmount,
          hasDiscount: item.discount !== null,
          spiceLevel: item.hasSpiceLevel ? "Low" : null,
          selectedModifiers: item.modifiers.map((modifier) => ({
            ...modifier,
            options: modifier.options.map((option) => ({
              ...option,
              hasSelected: false,
            })),
          })),
        },
      }));
    } else {
      setValues((x) => ({
        ...x,
        variantItemSel: item,
        bottomDrawerVariant: true,
      }));
    }
  };
  return (
    <div
      className="flex flex-col flex-1 max-w-full"
      style={{ height: window.innerHeight }}
    >
      {/* {SwipeableTemporaryDrawer(data, setCategories, categories)} */}
      {isLoading ? (
        LoadingPage()
      ) : (
        <>
          {error ? (
            navigate("/fetch_error")
          ) : (
            <Container className="flex flex-col flex-1">
              {/* appbar */}
              <div
                id="novus-appbar"
                className="py-3 flex items-center min-h-9 sticky top-0 bg-pure z-10 opacity-100"
              >
                {/* logo and table name*/}
                {(pageOnTop || !changeAppbar) && (
                  <div className="flex gap-3 items-center">
                    <Avatar
                      src={logo}
                      onClick={e=>navigate('/landing')}
                      className="w-9 h-[35px]"
                      variant="rounded"
                    />
                    <div
                      className="text-item6 caption tracking-[0.8px] uppercase 
              rounded-[3px] py-1 px-3
              border border-solid border-item6 max-w-[70px] overflow-hidden max-h-5"
                    >
                      {tableName}
                    </div>
                  </div>
                )}
                {/* business name when scrolled */}
                {!pageOnTop && changeAppbar && (
                  <h3
                    className={`font-semibold ${pageOnTop ? "opacity-0" : "opacity-100 transition-opacity"
                      }`}
                  >
                    {businessName}
                  </h3>
                )}
                {/* links */}
                <div className={`flex gap-3 items-center ml-auto h-full`}>
                  {/* user profile */}
                  <>
                    {getUser()?.image ? (
                      <Avatar
                        src={getUser()?.image}
                        onClick={(e) => handleClickUser(navigate)}
                        size={36}
                        variant="circular"
                      />
                    ) : (
                      <UserCircle
                        onClick={(e) => handleClickUser(navigate)}
                        size={36}
                      />
                    )}
                  </>
                </div>
              </div>
              {/* social media, feedback and notify waiter */}
              {FeedbackAndNotify(socialMedia, handleOpenNotifyWaiter, dispatch, navigate)}
              {/* search and tabs */}
              {TabsComponent(searchText, handleSearch, setValues,
                tabValue, categories, handleChangeTabs,
                setCategories, data, state, setState)}
              {/* show order status */}
              {(orderStatus === OrderStatusType.COOKING ||
              orderStatus === OrderStatusType.PREPARING ||
                orderStatus === OrderStatusType.NEW) && (
                  <div
                    className="flex py-[10px] px-3 justify-between gap-3 rounded-xl bg-primary_light mt-3"
                    onClick={(e) => {
                      navigate("/order_status");
                    }}
                  >
                    <div className="flex flex-col gap-[2px]">
                      <span className="subtitle-semibold text-primary">
                        {orderStatus === OrderStatusType.COOKING
                          ? "Cooking your food"
                          : "Processing your order"}
                      </span>
                      <span className="caption">
                        Chef at work! Please wait while we prepare food for you
                      </span>
                    </div>
                    {/* order status */}
                    <div
                      className="flex max-h-[50px] rounded-[50%] justify-center items-center bg-pure px-[5px]"
                      style={{
                        filter:
                          "drop-shadow(10px 10px 50px rgba(45, 87, 226, 0.10))",
                      }}
                    >
                      <img
                        src={
                          orderStatus === OrderStatusType.COOKING
                            ? cookingGIF
                            : preparingGIF
                        }
                        alt=""
                        className="w-10 h-10 rounded-[50%] bg-red-200"
                      />
                    </div>
                  </div>
                )}

              {/* items body */}
              <div
                className={`flex flex-col flex-grow gap-6 ${userCart?.items?.length > 0 ? "pb-20" : "pb-5"
                  } ${categories?.length>0 ? 'mt-6' : 'mt-0 flex-grow-0'}`}
              >
                {categories?.map((each, i) => (
                  <div
                    id={'home_tabs_body_each_tabPanel_' + i}
                    className={`${!each.items && "hidden"}`}
                    key={"home_tabs_body_each_tabPanel_" + i}
                  >
                    {each.items && (
                      <div id={`tabPanel${i}`} key={"novus_cat_" + i}>
                        {each.items && (
                          <div
                            className="flex flex-col gap-3"
                            key={"homeItem" + i}
                          >
                            {/* cetegory name */}
                            <span className="title-semibold capitalize">
                              {each.name}
                            </span>
                            {/* category */}
                            <div className="flex flex-col gap-3">
                              {/* items cards */}
                              {each.items?.map((eachItem, ind) => (
                                <div
                                  className="flex relative"
                                  key={"homepage_item_" + i + ind}
                                >
                                  <div
                                    onClick={(e) => handleItemClick(eachItem)}
                                    key={"homeItem_each" + ind}
                                    className={`flex relative flex-1 py-[10px] px-3 gap-3 rounded-xl h-auto
                                                                        border_light-gray cursor-pointer items-start`}
                                  >
                                    {/* left */}
                                    <div
                                      className={`flex items-start justify-between ${eachItem.image
                                        ? "flex-col h-full w-[calc(100%-87px)]"
                                        : "items-center w-full"
                                        }`}
                                    >
                                      {/* name and desc*/}
                                      <div className="flex flex-col">
                                        {/* name */}
                                        <div className="flex gap-[6px] items-center">
                                          {!eachItem.hasVariants && (
                                            <>
                                              {(eachItem.variants?.[0]?.isVeg === true) && (
                                                <SquareLogo
                                                  color="#33D29C"
                                                  weight="fill"
                                                  className="title-semibold"
                                                />
                                              )}
                                              {(eachItem.variants?.[0]?.isVeg === false) && (
                                                <SquareLogo
                                                  color="#E22B41"
                                                  weight="fill"
                                                  className="title-semibold"
                                                />
                                              )}
                                            </>
                                          )}
                                          <span className="body-semibold self-start text-primary capitalize line-clamp-1">
                                            {eachItem.name}
                                          </span>
                                        </div>
                                        {/* desc */}
                                        <span className="line-clamp-2 caption text-dark_gray">
                                          {!eachItem.hasVariants &&
                                            eachItem.variants?.[0]?.description}
                                        </span>
                                      </div>
                                      {/* price */}
                                      {!eachItem.hasVariants && (
                                        <div className="flex gap-1 subtitle min-w-fit ml-2">
                                          <span className="subtitle">
                                            Rs.{" "}
                                            {
                                              eachItem.variants?.[0]
                                                ?.discountedPrice
                                            }
                                          </span>
                                          {eachItem.discount && (
                                            <span className="line-through subtitle text-dark_gray">
                                              Rs.{" "}
                                              {eachItem.variants?.[0]?.price}
                                            </span>
                                          )}
                                        </div>
                                      )}
                                      {eachItem.hasVariants && (
                                        <div className="flex caption min-w-fit">
                                          Starting from Rs.{" "}
                                          {
                                            eachItem.variants?.[0]
                                              ?.discountedPrice
                                          }
                                        </div>
                                      )}
                                    </div>
                                    {/* right */}
                                    {eachItem.image && (
                                      <div
                                        className="w-[75px] h-[75px] ml-auto bg-cover bg-center rounded-5"
                                        style={{
                                          backgroundImage: `url(${eachItem.image})`,
                                        }}
                                      />
                                    )}
                                    {/* overlay for out of stock */}
                                    {/* {!eachItem.hasVariants &&
                                                                            eachItem.variants?.[0]?.inStock === 0 && (
                                                                                <div className="absolute flex items-center rounded-xl justify-center top-0 bottom-0 w-full bg-text opacity-50"></div>
                                                                            )}
                                                                        {!eachItem.hasVariants &&
                                                                            eachItem.variants?.[0]?.inStock === 0 && (
                                                                                <div className="absolute flex items-center justify-center top-0 bottom-0 w-full rounded-xl">
                                                                                    <div
                                                                                        className="bg-text w-fit subtitle-semibold
                                       text-pure rounded-5 py-[6px] px-2 opacity-50"
                                                                                    >
                                                                                        Currently Unavailable
                                                                                    </div>
                                                                                </div>
                                                                            )} */}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {/* no items yet */}
              {categories?.length === 0 && (
                <div className="flex flex-1 justify-center">
                  <div
                    className="flex flex-col items-center gap-8"
                    style={{ width: "71vw" }}
                  >
                    <img src={search_empty} alt="" />
                    <div className="flex flex-col gap-2 text-center">
                      <span className="title-semibold">
                        Oops, looks like the food went on a secret vacation! 🌴
                      </span>
                      <span className="subtitle text-dark_gray">
                        No results found for now
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </Container>
          )}
        </>
      )
      }

      {/* review order button */}
      {ReviewOrderButton(userCart ?? {}, navigate)}
      {/* open variant item bottom drawer  */}
      {VariantsItemDrawer(variantItemSel, bottomDrawerVariant, setValues)}
      {/*FAB for order status */}
      {
        showCookingStatus &&
        (orderStatus === OrderStatusType.COOKING ||
          orderStatus === OrderStatusType.NEW) && (
          <Fab
            onClick={(e) => {
              navigate("/order_status");
            }}
            className={`fixed right-4 bg-pure border-[3px] border-solid border-success
 ${userCart?.items?.length > 0 ? "bottom-[75px]" : "bottom-4"}`}
          >
            {OrderStatusCompImg(orderStatus)}
          </Fab>
        )
      }

      {/* Add to cart */}
      {
        CartModal(
          modalItem,
          modalData,
          openModal,
          setValues,
          userCart ?? {},
          dispatch
        )
      }
    </div>
  );
}
export default Homepage;

const TabsComponent = (searchText, handleSearch, setValues, tabValue, categories, handleChangeTabs,
  setCategories, data, state, setState
) => {
  return (
    <div
      id="searchAndTabs"
      className={`sticky pt-2 gap-3 bg-pure z-10 max-w-full overflow-hidden`}
    >
      {/* search */}
      <div className="flex gap-2">
        <div
          className="z-[9999] flex flex-1 px-4 pl-2 h-10 border border-solid
border-light_gray rounded-3xl items-center"
        >
          <div style={{ flex: "1" }}>
            <input
              id="search-div-big"
              type="text"
              placeholder="Search menu"
              className="body-regular placeholder:text-dark_gray text-dark z-50 pl-2"
              style={{
                width: "100%",
                border: "none",
                outline: "none",
              }}
              value={searchText}
              onChange={handleSearch}
            />
          </div>
          {isTextEmpty(searchText) ? (
            <MagnifyingGlass className="text-primary" size={20} />
          ) : (
            <X
              className="text-primary"
              size={20}
              onClick={(e) => {
                setValues((x) => ({
                  ...x,
                  searchText: "",
                }));
                setCategories(data);
              }}
            />
          )}
        </div>
        {FiltervegDrawer(data, setCategories, categories, state, setState)}
      </div>
      {/* tabs */}
      <div
        id="home-tabs-body"
        className="flex gap-2 no-scrollbar overflow-x-auto"
      // className="flex gap-2 overflow-y-hidden overflow-x-auto opacity-100 no-scrollbar"
      >
        {categories.map((each, i) => (
          <div
            key={"home_tabs_each_tab_" + i}
            className={`${!each.items && "hidden"}`}
          >
            {each.items && (
              <Button
                key={"categories_tab_" + i}
                onClick={(e) => handleChangeTabs(e, i)}
                className={`w-max rounded-none flex flex-col justify-center items-center
   py-3 px-[10px] capitalize ${tabValue === i
                    ? "text-primary body-semibold border-solid border-t-0 border-x-0 border-b-2 border-indigo-500"
                    : "body-regular text-text"
                  }`}
              >
                <span>{each.name}</span>
              </Button>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

function searchMenuItems(searchQuery, data) {
  if (!searchQuery) {
    return data;
  }

  searchQuery = searchQuery.toLowerCase();

  const filteredData = data
    .map((category) => {
      const categoryMatches = category.name.toLowerCase().includes(searchQuery);

      if (categoryMatches) {
        const categoryCopy = { ...category };
        categoryCopy.items = category.items?.slice(); // Make a shallow copy of items to avoid modifying the original data.
        return categoryCopy;
      }

      const matchingItems =
        category.items?.filter((item) => {
          const matchingVariants = item.variants.filter((variant) =>
            variant.name.toLowerCase().includes(searchQuery)
          );
          return (
            item.name.toLowerCase().includes(searchQuery) ||
            matchingVariants?.length > 0
          );
        }) || [];

      if (matchingItems?.length > 0) {
        const categoryCopy = { ...category };
        categoryCopy.items = matchingItems;
        return categoryCopy;
      }

      return null;
    })
    .filter(Boolean);

  return filteredData;
}

const OrderStatusCompImg = (orderStatus) => {
  return (
    <div
      className="flex"
      style={{
        filter: "drop-shadow(10px 10px 50px rgba(45, 87, 226, 0.10))",
      }}
    >
      <img
        src={
          orderStatus === OrderStatusType.COOKING ? cookingGIF : preparingGIF
        }
        alt=""
        className="w-10 h-10 rounded-[50%] bg-red-200"
      />
    </div>
  );
};

const FeedbackAndNotify = (socialMedia, handleOpenNotifyWaiter, dispatch, navigate) => {
  const userId = getUserId()
  return (
    <div className="flex mt-2 mb-4 h-9 items-center">
      {SocialMediaComp(socialMedia)}
      <div className="flex gap-2 ml-auto">
        {/* Feedback */}
        {userId && <Button
          className="flex bg-pure subtitle-semibold text-text 
                                border border-solid border-text
                                    ml-auto py-[6px] px-2 rounded-3xl gap-2"
          onClick={(e) => navigate('/feedback')}
        >
          <PencilSimple size={16} />
          <span>Feedback</span>
        </Button>}
        {/* notify waiter */}
        <Button
          className="flex bg-primary subtitle-semibold text-pure
                                    ml-auto py-[6px] px-2 rounded-3xl gap-2"
          onClick={(e) => handleOpenNotifyWaiter(dispatch)}
        >
          <CallBell size={16} />
          <span>Notify Waiter</span>
        </Button>
      </div>
    </div>
  )
}