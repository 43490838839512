import { Box, Button, Container, Drawer, IconButton } from "@mui/material";
import { Minus, Plus, SquareLogo, X } from "@phosphor-icons/react";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MUIModal } from "../../common/MUI";
import { getSpiceText, getSpiceValue, isTextEmpty } from "../../common/common";
import { AirbnbThumbComponent, SpiceSlider } from "../../common/components";
import { spiceDisplayLabel } from "../../common/datasets";
import { user_cart } from "../Layout/userLayoutSlice";
import {
    handleAddOptionQuantityATC,
    handleAddtoCart,
    handleCancelAddtoCartModal,
    handleChangeQuantityATC,
} from "./AddToCartModal";

export const VariantsItemDrawer = (
    variantItemSel,
    bottomDrawerVariant,
    setValues
) => {
    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setValues((x) => ({ ...x, bottomDrawerVariant: open }));
    };
    return (
        <Fragment>
            <Drawer
                anchor={"bottom"}
                open={bottomDrawerVariant}
                onClose={toggleDrawer(false)}
            >
                <Box sx={{ width: "auto" }} role="presentation">
                    {VariantsItemPage(variantItemSel, setValues)}
                </Box>
            </Drawer>
        </Fragment>
    );
};

function VariantsItemPage(item, setFn) {
    const stateData = item;
    const dispatch = useDispatch();
    const userCart = useSelector(user_cart);
    const [values, setValues] = useState({
        openModal: false,
        modalItem: null,
        modalData: {
            name: "",
            itemId: "",
            variantId: "",
            quantity: 1,
            basePrice: null,
            price: null,
            spiceLevel: "Low",
            note: "",
            selectedModifiers: [],
        },
    });
    const { openModal, modalItem, modalData } = values;
    const handleVariantClick = (item) => {
        setValues((x) => ({
            ...x,
            openModal: true,
            modalItem: stateData,
            modalData: {
                name: stateData.name,
                variantName: item.name,
                hasVariant: true,
                itemId: stateData.id,
                variantId: item.id,
                isVeg: item.isVeg,
                quantity: 1,
                totalSelModPrice: 0,
                price: item.price,
                discountedPrice: item.discountedPrice,
                discountAmount: item.discountAmount,
                hasDiscount: item.discountAmount !== 0,
                spiceLevel: stateData.hasSpiceLevel ? "Low" : null,
                selectedModifiers: stateData.modifiers.map((modifier) => ({
                    ...modifier,
                    options: modifier.options.map((option) => ({
                        ...option,
                        hasSelected: false,
                    })),
                })),
            },
        }));
    };
    if (!stateData) return;
    return (
        <Container
            className="flex flex-col flex-grow gap-4 py-4"
            style={{ height: window.innerHeight }}
        >
            {/* top */}
            <div className="flex h-7 justify-between">
                <div className="flex items-center gap-2">
                    <span className="title-semibold capitalize">{stateData.name}</span>
                </div>
                <IconButton
                    onClick={(e) => setFn((x) => ({ ...x, bottomDrawerVariant: false }))}
                >
                    <X size={24} />
                </IconButton>
            </div>
            {/* content */}
            <div className="flex flex-col gap-3">
                {stateData.img && (
                    <div
                        className="w-[100%] h-[200px] ml-auto bg-cover bg-center rounded-5"
                        style={{
                            backgroundImage: `url(${stateData.img})`,
                        }}
                    />
                )}
                <span className="subtitle">{stateData.description}</span>
                <div className="flex flex-col gap-3">
                    {stateData.variants?.map((each, i) => (
                        <Button
                            onClick={(e) => handleVariantClick(each)}
                            key={"eachVariant" + i}
                            className="flex flex-col items-start gap-[6px] py-3 px-4 
                  rounded-xl border_light-gray text-text"
                        >
                            <div className="flex justify-between w-full items-center">
                                <div className="flex gap-2 items-center">
                                    {(each?.isVeg===true) && (
                                        <SquareLogo
                                            color="#33D29C"
                                            weight="fill"
                                            className="title-semibold"
                                        />
                                    )} 
                                    {(each?.isVeg===false) &&(
                                        <SquareLogo
                                            color="#E22B41"
                                            weight="fill"
                                            className="title-semibold"
                                        />
                                    )}
                                    <span className="title-semibold capitalize">{each.name}</span>
                                </div>
                                {!isTextEmpty(each.price) && (
                                    <span className="body-semibold text-dark_gray ml-1 min-w-fit">
                                        Rs. {each.price}
                                    </span>
                                )}
                            </div>
                            <span className="caption text-dark_gray name_two_lines">
                                {each.description}
                            </span>
                        </Button>
                    ))}
                </div>
            </div>
            {/* modal */}
            {modalItem && (
                <MUIModal
                    handleClose={(e) => handleCancelAddtoCartModal(setValues)}
                    openModal={openModal && modalItem}
                >
                    {/* header */}
                    <div className="flex justify-between py-3 px-4 sticky top-0 bg-pure z-50">
                        <div className="flex gap-2 items-center">
                            {(modalData?.isVeg===true) && (
                                <SquareLogo
                                    color="#33D29C"
                                    weight="fill"
                                    className="title-semibold"
                                />
                            )} 
                            {(modalData?.isVeg===false) &&(
                                <SquareLogo
                                    color="#E22B41"
                                    weight="fill"
                                    className="title-semibold"
                                />
                            )}
                            <span className="title-semibold capitalize">
                                {modalData.name}
                            </span>
                        </div>
                        <div className="flex gap-1 body-regular min-w-fit ml-2">
                            <span className="">
                                Rs. {parseFloat(modalData.discountedPrice).toFixed(2)}
                            </span>
                        </div>
                    </div>
                    {/* body */}
                    <div className="flex flex-col gap-4 mt-1 p-4">
                        {/* modifiers */}
                        {modalData?.selectedModifiers?.map((eachMod, i) => (
                            <div
                                className="flex flex-col gap-2"
                                key={`modalItem_modifier` + i}
                            >
                                <span className="subtitle-semibold text-dark_gray capitalize">
                                    {eachMod.name}
                                </span>
                                {eachMod?.options?.map((eachOp, ind) => (
                                    <div
                                        className="flex justify-between border_light-gray py-2 px-4 rounded-xl items-center"
                                        key={"modalItem_modifier" + ind}
                                        onClick={(e) =>
                                            handleAddOptionQuantityATC(
                                                i,
                                                ind,
                                                1,
                                                eachOp.price,
                                                modalData,
                                                setValues
                                            )
                                        }
                                    >
                                        <div className="flex flex-col gap-[2px]">
                                            <span className="subtitle-semibold capitalize">
                                                {eachOp.name}
                                            </span>
                                            <span className="caption">+Rs. {eachOp.price}</span>
                                        </div>
                                        {/* quantity */}
                                        <div className="flex gap-1">
                                            {eachOp.quantity > 0 && (
                                                <Button
                                                    className="bg-dark_gray rounded-[3px] w-8 h-8 text-pure p-0"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleAddOptionQuantityATC(
                                                            i,
                                                            ind,
                                                            -1,
                                                            eachOp.price,
                                                            modalData,
                                                            setValues
                                                        );
                                                    }}
                                                >
                                                    <Minus size={20} color="#fff" />
                                                </Button>
                                            )}
                                            {eachOp.quantity > 0 && (
                                                <Button className="rounded-[3px] w-8 h-8 p-0 text-text subtitle">
                                                    {eachOp.quantity}
                                                </Button>
                                            )}
                                            <Button
                                                className="bg-dark_gray rounded-[3px] w-8 h-8 text-pure p-0"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleAddOptionQuantityATC(
                                                        i,
                                                        ind,
                                                        1,
                                                        eachOp.price,
                                                        modalData,
                                                        setValues
                                                    );
                                                }}
                                            >
                                                <Plus size={15} color="#fff" />
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                        {/* spice level */}
                        {modalItem.hasSpiceLevel && (
                            <div className="flex flex-col gap-2">
                                <span className="subtitle-semibold text-dark_gray">
                                    Spice Level
                                </span>
                                <div className="flex flex-col px-4">
                                    <span className="body-semibold text-secondary self-center">
                                        {modalData.spiceLevel}
                                    </span>
                                    <SpiceSlider
                                        marks={spiceDisplayLabel}
                                        slots={{ thumb: AirbnbThumbComponent }}
                                        getAriaLabel={(index) =>
                                            index === 0 ? "Minimum price" : "Maximum price"
                                        }
                                        value={getSpiceValue(modalData.spiceLevel)}
                                        onChange={(e, newVal) =>
                                            setValues((x) => ({
                                                ...x,
                                                modalData: {
                                                    ...modalData,
                                                    spiceLevel: getSpiceText(newVal),
                                                },
                                            }))
                                        }
                                        step={null}
                                    />
                                </div>
                            </div>
                        )}
                        {/* quantity */}
                        <div className="flex justify-between">
                            <span className="subtitle-semibold text-dark_gray">Quantity</span>
                            <div className="flex gap-[6px] h-[22px]">
                                <div
                                    onClick={(e) =>
                                        handleChangeQuantityATC(-1, modalData, setValues)
                                    }
                                    className="w-8 h-8 bg-dark_gray flex
                 justify-center items-center rounded-[3px]"
                                >
                                    <Minus size={20} color="#fff" />
                                </div>
                                <div className="w-8 h-8 subtitle flex items-center justify-center">
                                    {modalData.quantity}
                                </div>
                                <div
                                    onClick={(e) =>
                                        handleChangeQuantityATC(1, modalData, setValues)
                                    }
                                    className="w-8 h-8 bg-dark_gray flex justify-center items-center rounded-[3px]"
                                >
                                    <Plus size={20} color="#fff" />
                                </div>
                            </div>
                        </div>
                        {/* message */}
                        <div className="flex flex-col gap-2">
                            <span className="text-dark_gray subtitle-semibold">Message</span>
                            <textarea
                                name="message"
                                id="message"
                                rows="4"
                                value={modalData.note}
                                onChange={(e) =>
                                    setValues((x) => ({
                                        ...x,
                                        modalData: { ...modalData, note: e.target.value },
                                    }))
                                }
                                className="rounded-xl border_light-gray focus:outline-none p-1 body-regular"
                            ></textarea>
                        </div>
                    </div>
                    {/* buttons */}
                    <div
                        className="flex justify-end self-stretch gap-2
           border-solid border-0 border-t-2 border-almost_white py-3 px-4
          sticky bottom-0 bg-pure"
                    >
                        <Button
                            className="body-semibold w-28 text-primary"
                            onClick={(e) => handleCancelAddtoCartModal(setValues)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="btn-primary-small w-28 rounded-xl"
                            onClick={(e) => {
                                handleAddtoCart(
                                    userCart,
                                    modalData,
                                    dispatch,
                                    setValues,
                                    setFn((x) => ({ ...x, bottomDrawerVariant: false }))
                                );
                            }}
                        >
                            Add
                        </Button>
                    </div>
                </MUIModal>
            )}
        </Container>
    );
}
