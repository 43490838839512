import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import loading_table from "../../assets/loading_table.gif";
import { saveScannedTable, setInitailCart } from "../../common/cookie";
import { BrokenPage } from "../ErrorPages/FetchError";
import { useGetTableStatusQuery } from "../slices/apiSlice";
import { setSocialMedia } from "./userLayoutSlice";

function ScannedPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { tableId, businessId } = useParams();
    var [errorValue, setErrorValue] = useState(false);
    const { data, isLoading, error, refetch } = useGetTableStatusQuery({
        tableId: tableId,
        businessId: businessId,
    });
    if (error?.status === "FETCH_ERROR") {
        navigate("/fetch_error");
    }
    const [underOneSec, setIsOneSec] = useState(true);
    const changeValueToTrue = () => {
        setTimeout(() => {
            setIsOneSec(false);
        }, 1500); // 1000 milliseconds (1 second)
    };
    changeValueToTrue();
    useEffect(() => {
        setTimeout(() => {
            scannFn();
        }, 1000);
        function scannFn() {
            if (!businessId || !tableId) setErrorValue(true);
            else {
                dispatch(setSocialMedia(data?.socialMedias ?? null))
                if (data?.isOccupied) {
                    // already occupied
                    saveScannedTable(businessId, tableId, data.info);
                    navigate("/occupied", { state: { ...data, tableId, businessId } });
                } else if (!isLoading && !error) {
                    // save businessId and tableId
                    saveScannedTable(businessId, tableId, data.businessInfo);
                    setInitailCart(businessId, tableId, dispatch);
                    navigate("/landing");
                }
                else if (error) {
                    setErrorValue(true)
                }
            }
        }
    }, [businessId, tableId, navigate, data, isLoading, dispatch, error]);

    const fetchTableStatusData = () => {
        refetch()
    }

    if (underOneSec) {
        return (
            <div className="flex flex-col flex-1 items-center justify-center">
                <img src={loading_table} className="w-[50vw]" alt="" />
            </div>
        );
    } else if (isLoading) {
        return (
            <div className="flex flex-col flex-1 items-center justify-center">
                <img src={loading_table} className="w-[50vw]" alt="" />
            </div>
        );
    } else if (errorValue) return <BrokenPage onTryAgainClick={fetchTableStatusData} />;
}

export default ScannedPage;
