import { IconButton } from "@mui/material";
import { CheckCircle, X } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify_food from "../../assets/notify_food.png";
import notify_overlay from "../../assets/notify_overlay.svg";
import "../../index.css";
import { useNotifyWaiterMutation } from "../slices/apiSlice";
import { handleCloseNotifyWaiter } from "./Layout";
import { ad_businessId, ad_tableId, selected_service, setError } from "./userLayoutSlice";
import { getBusinessId, getTableId } from "../../common/cookie";

function NotifyWaiter({ action }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [notified, setNotified] = useState(false);
    const businessId = useSelector(ad_businessId);
    const tableId = useSelector(ad_tableId);
    const [NotifyWaiter] = useNotifyWaiterMutation();
    const selected = useSelector(selected_service)

    useEffect(() => {
        const fetchData = async () => {
            if (!notified) {
                try {
                    await NotifyWaiter({
                        businessId: getBusinessId(),
                        id: getTableId(),
                        action: action,
                    }).unwrap();
                    setNotified(true);
                } catch (error) {
                    dispatch(
                        setError({
                            errorPosition: "apiErr",
                            errorText: error.data?.message,
                        })
                    );
                    handleCloseNotifyWaiter(dispatch, navigate, selected);
                }
            }
        };

        fetchData();
    }, [notified, NotifyWaiter, dispatch, businessId, tableId, action, navigate, selected]);

    return (
        <div
            className="flex flex-col w-[100%] bg-cover fixed top-0 bottom-0 overflow-hidden
       bg-center bg-opacity-80 z-[9999]"
            style={{
                backgroundImage: `url(${notify_overlay})`,
            }}
        >
            <IconButton
                className="bg-pure absolute right-4 top-6"
                onClick={(e) => handleCloseNotifyWaiter(dispatch, navigate, selected)}
            >
                <X size={32} className="text-text" />
            </IconButton>
            <div className="flex flex-col items-center justify-center w-screen flex-1">
                <span className="mt-auto mb-28 body-semibold text-pure">
                    {notified ? "Waiter has been notified" : "Notifying waiter"}
                </span>
                <div class="notify_wrap h-[50vh]">
                    <button className="notify_button">
                        {notified ? (
                            <CheckCircle weight="thin" size={59} color="#fff" />
                        ) : (
                            <img src={notify_food} alt="" />
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default NotifyWaiter;
