import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './Pages/Auth/Login';
import { Error404, FetchError } from './Pages/ErrorPages/FetchError';
import Homepage from './Pages/HomePage/Homepage';
import { ReviewOrderDrawerPage } from './Pages/HomePage/ReviewOrder';
import { Layout } from './Pages/Layout/Layout';
import NotifyWaiter from './Pages/Layout/NotifyWaiter';
import Occupied, { OccupiedWhoopsie } from './Pages/Layout/Occupied';
import ScannedPage from './Pages/Layout/ScannedPage';
import CheckComponent from './Pages/Order/CheckComponent';
import { OrderStatus } from './Pages/Order/OrderStatus';
import EditPhone from './Pages/UserProfile/EditPhone';
import EditUserProfile from './Pages/UserProfile/EditUserProfile';
import OrderHistory, { OneOrderHistory, OrderHistoryLayout } from './Pages/UserProfile/OrderHistory';
import UserProfile from './Pages/UserProfile/UserProfile';
import Feedback from './Pages/Feedback/Feedback';
import LandingPage from './Pages/Layout/LandingPage';
// customer.novusnepal.com/f5ad86e-816d-4285-9970-66f335950835/1ca07676-5d94-4125-8c95-ae3c215c0405

// /api/floors/tables/customer/8cf26988-8e73-40a1-81a2-49279a51ef4b/1ca07676-5d94-4125-8c95-ae3c215c0405
// local: ktm delights
// http://192.168.1.83:3000/8cf26988-8e73-40a1-81a2-49279a51ef4b/1ca07676-5d94-4125-8c95-ae3c215c0405
// http://192.168.1.83:3000/6f3d3c6e-ff94-445a-a125-7db7ef5a10a0/1ca07676-5d94-4125-8c95-ae3c215c0405
// 
function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout />} >
                    <Route path='/login' element={<Login />} />
                    <Route path='/:tableId/:businessId' element={<ScannedPage />} />
                    <Route path='/occupied' element={<Occupied />} />
                    <Route path='/landing' element={<LandingPage />} />
                    <Route path='/' element={<Homepage />} />
                    <Route path='/review' element={<ReviewOrderDrawerPage />} />
                    <Route path='/order_status' element={<OrderStatus />} />
                    <Route path='/check' element={<CheckComponent />} />
                    <Route path='/notify' element={<NotifyWaiter />} />
                    <Route path='/feedback' element={<Feedback />} />
                    {/* profile */}
                    <Route path='/profile' element={<UserProfile />} />
                    <Route path='/profile/edit' element={<EditUserProfile />} />
                    <Route path='/profile/editPhone' element={<EditPhone />} />
                    <Route path='/profile/history' element={<OrderHistoryLayout />} >
                        <Route index element={<OrderHistory />} />
                        <Route path=':id' element={<OneOrderHistory />} />
                    </Route>
                    <Route path='/404' element={<Error404 />} />
                    <Route path='/whoopsie' element={<OccupiedWhoopsie />} />
                    <Route path='/fetch_error' element={<FetchError />} />

                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
