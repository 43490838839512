import { Box, Button, IconButton } from "@mui/material";
import OTPInput from "otp-input-react";
import { ArrowLeft, X } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import flag from "../../assets/flag_of_nepal.png";
import { LabelComp, isTextEmpty } from "../../common/common";
import { CircularProgressNovus } from "../../common/components";
import { saveLoginCookie, setUserCartCookie } from "../../common/cookie";
import { selected_service, setCartItem, setError, setSocket, user_cart } from "../Layout/userLayoutSlice";
import { useCreateOrderMutation } from "../slices/apiSlice";
import {
    useCheckUserMutation,
    useLogisterMutation,
    useSendOTPMutation,
} from "./authSlice";

function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userCart = useSelector(user_cart);
    const [createOrder] = useCreateOrderMutation();
    const [checkUser] = useCheckUserMutation();
    const [sendOTP] = useSendOTPMutation();
    const [logister] = useLogisterMutation();
    const [values, setValues] = useState({
        step: 1,
        phone: "",
        name: "",
        email: "",
        remainingSeconds: 0,
        login_loading: false,
        errorPosition: "",
        errorText: "",
    });
    const {
        step,
        phone,
        name,
        email,
        remainingSeconds,
        login_loading,
        errorPosition,
        errorText,
    } = values;
    const inputRefs = useRef([]);
    const [otp, setOTP] = useState("");
    const phoneNumberRegex = /^9\d{9}$/;
    const otpRegex = /^\d{5}$/;
    const canContinueNumber = phoneNumberRegex.test(phone);
    const canContinueRegister =
        phoneNumberRegex.test(phone) && !isTextEmpty(name);
    const canContinueOTP = otpRegex.test(otp);
    const selected = useSelector(selected_service)
    // for timer resend
    useEffect(() => {
        if (remainingSeconds > 0) {
            const timerId = setInterval(() => {
                setValues((x) => ({ ...x, remainingSeconds: x.remainingSeconds - 1 }));
                // setRemainingSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);

            return () => {
                clearInterval(timerId);
            };
        }
    }, [remainingSeconds]);
    //   handle enter from user input
    const handleKeyDown = (event, index) => {
        if (event.key === "Enter") {
            if (index === 0) {
                handleSubmit1();
                return;
            }
            if (index === 3) {
                handleSubmit2();
                return;
            } else if (index === 4) {
                handleSubmit3();
                return;
            }
            //   event.preventDefault();
            //   const nextIndex = index + 1;
            //   if (inputRefs?.current[nextIndex]) {
            //     inputRefs?.current[nextIndex]?.focus();
            //   }
        }
    };
    //   new
    const handleSubmit1 = async () => {
        try {
            if (isTextEmpty(phone))
                return setValues((x) => ({
                    ...x,
                    errorPosition: "phone",
                    errorText: "Invalid phone",
                }));
            setValues((x) => ({ ...x, login_loading: true }));
            const res = await checkUser({ phone }).unwrap();
            if (res?.error)
                return setValues((x) => ({
                    ...x,
                    errorPosition: "apiErr",
                    errorText: res.error,
                    login_loading: false,
                }));
            setValues((x) => ({
                ...x,
                step: res?.isNewUser ? 2 : 3,
                remainingSeconds: res?.isNewUser ? 0 : 60,
                login_loading: false,
            }));
            setOTP();
            inputRefs?.current[2]?.focus();
        } catch (error) {
            setValues((x) => ({ ...x, login_loading: false }));
            dispatch(
                setError({
                    errorPosition: "apiErr",
                    errorText: error.data?.message,
                })
            );
        }
    };
    const handleSubmit2 = async () => {
        try {
            if (isTextEmpty(phone))
                return setValues((x) => ({
                    ...x,
                    errorPosition: "phone",
                    errorText: "Invalid phone",
                }));
            else if (isTextEmpty(name))
                return setValues((x) => ({
                    ...x,
                    errorPosition: "name",
                    errorText: "Invalid name",
                }));
            setValues((x) => ({ ...x, login_loading: true }));
            const res = await sendOTP({ phone }).unwrap();
            if (res?.error)
                return setValues((x) => ({
                    ...x,
                    errorPosition: "apiErr",
                    errorText: res?.error,
                    login_loading: false,
                }));
            setValues((x) => ({
                ...x,
                step: 3,
                remainingSeconds: 60,
                login_loading: false,
            }));
            setOTP();
        } catch (error) {
            setValues((x) => ({ ...x, login_loading: false }));
            dispatch(
                setError({
                    errorPosition: "apiErr",
                    errorText: error.data?.message,
                })
            );
        }
    };
    const handleSubmit3 = async () => {
        try {
            if (isTextEmpty(otp) || otp.length !== 5)
                return setValues((x) => ({
                    ...x,
                    errorPosition: "otp",
                    errorText: "Invalid OTP",
                }));
            setValues((x) => ({ ...x, login_loading: true }));
            const send = {
                phone,
                email,
                otp,
                name,
            };
            const res = await logister(send).unwrap();
            if (res?.error)
                return setValues((x) => ({
                    ...x,
                    errorPosition: "apiErr",
                    errorText: res?.error,
                    login_loading: false,
                }));
            saveLoginCookie(res, res.expiresAt);
            dispatch(setSocket())
            if (window.location.pathname === "/login") {
                navigate(`/?services=${selected}`)
                dispatch(
                    setError({
                        errorPosition: "success",
                        errorText: "Logged in",
                    })
                );
                return;
            } else if (window.location.pathname === "/order_status") {
                navigate("/check");
                dispatch(
                    setError({
                        errorPosition: "success",
                        errorText: "Logged in",
                    })
                );
                return;
            }
            //   place order if coming from review order
            else await createOrder(userCart).unwrap();
            // completed
            dispatch(
                setCartItem({
                    cart: {
                        ...userCart,
                        items: [],
                        subTotal: 0,
                        discount: 0,
                    },
                })
            );
            setUserCartCookie({ ...userCart, items: [], subTotal: 0, discount: 0 });
            navigate("/order_status");
        } catch (error) {
            setValues((x) => ({ ...x, login_loading: false }));
            dispatch(
                setError({
                    errorPosition: "apiErr",
                    errorText: error.data?.message,
                })
            );
        }
    };
    const handleResendOTP = async () => {
        setValues((x) => ({ ...x, remainingSeconds: 60 }));
        handleSubmit2();
    };
    return (
        <Box
            className={`flex flex-col gap-2 flex-1 ${window.location.pathname === "/login" && "p-4"
                }`}
        >
            {/* top */}
            {window.location.pathname === "/login" ? (
                <div className="flex justify-start">
                    <IconButton onClick={(e) => navigate(-1)}>
                        <ArrowLeft color="#000" />
                    </IconButton>
                </div>
            ) : (
                <div className="flex justify-end">
                    <IconButton onClick={(e) => navigate(-1)}>
                        <X color="#000" />
                    </IconButton>
                </div>
            )}
            {/* step 1 */}
            {step === 1 && (
                <div className="flex flex-col flex-1 gap-6">
                    <h3>
                        Login to{" "}
                        <span className="text-primary font-semibold">Novus POS</span>
                    </h3>
                    {/* body */}
                    <div className="flex flex-col gap-2">
                        {LabelComp("Phone Number", "phone", errorPosition, errorText)}
                        <div className="flex w-full" style={{ height: "45px" }}>
                            <div
                                className="flex w-auto pr-1 border_light-gray items-center"
                                style={{
                                    borderRadius: "12px 0 0 12px",
                                }}
                            >
                                <img className="flag_img" src={flag} alt="" />
                                <span className="body-regular">+977</span>
                            </div>
                            <input
                                type="text"
                                className="h-100 flex w-full border-left-0 z-50 pl-2 border_light-gray body-regular focus:outline-none"
                                style={{
                                    borderRadius: "0 12px 12px 0",
                                }}
                                autoFocus
                                ref={(ref) => (inputRefs.current[0] = ref)}
                                onKeyDown={(event) => handleKeyDown(event, 0)}
                                value={phone}
                                onChange={(e) =>
                                    setValues((x) => ({
                                        ...x,
                                        phone: e.target.value,
                                    }))
                                }
                            />
                        </div>
                    </div>
                    <Button
                        className={`btn-primary-large w-full body-semibold ${!canContinueNumber && "opacity-50"
                            }`}
                        disabled={!canContinueNumber}
                        onClick={handleSubmit1}
                    >
                        {login_loading ? <CircularProgressNovus /> : "Login with OTP"}
                    </Button>
                </div>
            )}
            {/* step 2 */}
            {step === 2 && (
                <div className="flex flex-col flex-1 gap-6">
                    <h3>
                        Register to{" "}
                        <span className="text-primary font-semibold">Novus POS</span>
                    </h3>
                    {/* body */}
                    <div className="flex flex-col gap-4">
                        {/* number */}
                        <div className="flex flex-col gap-2">
                            {LabelComp("Phone Number", "phone", errorPosition, errorText)}
                            <div className="flex h-11">
                                <div
                                    className="flex h-fullw-auto px-2 items-center border_light-gray"
                                    style={{
                                        borderRadius: "12px 0 0 12px",
                                    }}
                                >
                                    <img className="flag_img" src={flag} alt="" />
                                    <span className="body-regular">+977</span>
                                </div>
                                <input
                                    type="text"
                                    className="flex flex-1 z-50 border-left-0 pl-2 border_light-gray body-regular 
                  focus:outline-none"
                                    style={{
                                        borderRadius: "0 12px 12px 0",
                                    }}
                                    ref={(ref) => (inputRefs.current[1] = ref)}
                                    onKeyDown={(event) => handleKeyDown(event, 1)}
                                    value={phone}
                                    onChange={(e) =>
                                        setValues((x) => ({
                                            ...x,
                                            phone: e.target.value,
                                        }))
                                    }
                                />
                            </div>
                        </div>
                        {/* name */}
                        <div className="flex flex-col gap-2">
                            {LabelComp("Full Name *", "name", errorPosition, errorText, "")}
                            <input
                                type="text"
                                value={name}
                                className="rounded-xl border_light-gray body-regular h-10 px-2"
                                autoFocus
                                ref={(ref) => (inputRefs.current[2] = ref)}
                                onKeyDown={(event) => handleKeyDown(event, 2)}
                                onChange={(e) =>
                                    setValues((prev) => ({ ...prev, name: e.target.value }))
                                }
                            />
                        </div>
                        {/* email */}
                        <div className="flex flex-col gap-2">
                            {LabelComp("Email", "email", errorPosition, errorText)}
                            <input
                                type="text"
                                value={email}
                                className="rounded-xl border_light-gray body-regular h-10 px-2"
                                ref={(ref) => (inputRefs.current[3] = ref)}
                                onKeyDown={(event) => handleKeyDown(event, 3)}
                                onChange={(e) =>
                                    setValues((prev) => ({ ...prev, email: e.target.value }))
                                }
                            />
                        </div>
                    </div>
                    {/* register */}
                    <Button
                        className={`btn-primary-large w-full body-semibold ${!canContinueRegister && "opacity-50"
                            }`}
                        disabled={!canContinueRegister}
                        onClick={handleSubmit2}
                    >
                        {login_loading ? <CircularProgressNovus /> : "Register"}
                    </Button>
                </div>
            )}
            {/* step 3 */}
            {step === 3 && (
                <div className={"flex flex-col flex-1 gap-3"}>
                    <h3>OTP Verification</h3>
                    {/* body */}
                    <div className="flex flex-col gap-5 mb-3">
                        <div className="d-flex gap-1 body-regular text-dark_gray">
                            Enter the code sent to{" "}
                            <span className="body-semibold text-primary">{phone}</span>
                        </div>
                    </div>
                    {/* otp */}
                    <div
                        className="flex justify-center"
                        onKeyDown={(e) => handleKeyDown(e, 4)}
                    >
                        <OTPInput
                            value={otp}
                            onChange={setOTP}
                            autoFocus
                            OTPLength={5}
                            otpType="number"
                            disabled={false}
                            inputClassName="otp_class body-regular"
                            inputStyles={{
                                width: "40px",
                                height: "60px",
                                outline: "none",
                                border: "1px solid #E9ECFF",
                                borderRadius: "12px",
                            }}
                            className="w-fit"
                        />
                    </div>
                    {/* remaining seconds */}
                    {remainingSeconds === 0 ? (
                        <div
                            className="flex gap-2 justify-center cursor_pointer body-regular cursor-pointer mb-3"
                            onClick={handleResendOTP}
                        >
                            Didn’t receive OTP?
                            <span className="text-link">Resend</span>
                        </div>
                    ) : (
                        <div className="flex justify-center gap-1 body-regular mb-3">
                            <span>{remainingSeconds}s</span>
                            <span>until resend</span>
                        </div>
                    )}
                    {/* verify */}
                    <Button
                        className={`btn-primary-large w-full body-semibold ${!canContinueOTP && "opacity-50"
                            }`}
                        disabled={!canContinueOTP}
                        onClick={handleSubmit3}
                    >
                        {login_loading ? <CircularProgressNovus /> : "Verify"}
                    </Button>
                </div>
            )}
        </Box>
    );
}

export default Login;
