import React from "react";
import loading_burger from "../../assets/loading_burger.gif";
import loading_pizza from "../../assets/loading_pizza.gif";

function LoadingPage() {
  return (
    <div className="flex justify-center flex-1 w-full items-center">
      <img src={loading_burger} alt="" className="w-[250px] h-[250px]" />
    </div>
  );
}

export default LoadingPage;

export const LoadingPageGeneral = () => {
  return (
    <div className="flex justify-center bg-info w-full items-center">
      <img src={loading_pizza} alt="" className="w-[250px] h-[250px]" />
    </div>
  );
};
