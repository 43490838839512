import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    List,
    Slide,
    Slider,
    SliderThumb,
    Snackbar,
    SwipeableDrawer,
    styled
} from "@mui/material";
import { CheckCircle, SlidersHorizontal, WarningCircle } from "@phosphor-icons/react";
import React from "react";
import { useDispatch } from "react-redux";
import { handleOpenNotifyWaiter } from "../Pages/Layout/Layout";
import { clearAdminError } from "../Pages/Layout/userLayoutSlice";
import fireEmoji from "../assets/emojione-fire.png";
import { facebook_icon, insta_icon, tiktok_icon } from "./datasets";

export const AlertSnack = (errorPosition, errorText) => {
    const dispatch = useDispatch();
    function SlideTransition(props) {
        return <Slide {...props} direction="down" />;
    }
    const transition = SlideTransition;

    if (errorPosition === "apiErr")
        return (
            (errorPosition === "mainErr" ||
                errorPosition === "apiErr" ||
                errorPosition === "success") && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    TransitionComponent={transition}
                    open={
                        errorPosition === "mainErr" ||
                        errorPosition === "apiErr" ||
                        errorPosition === "success"
                    }
                    autoHideDuration={1000}
                    onClose={() => dispatch(clearAdminError())}
                    message="I love snacks"
                    key={"top+center"}
                >
                    <div
                        className="h-11 gap-3 text-white subtitle justify-center items-center flex
        px-3 py-1
        bg-error shadow-[0px 72px 132px rgba(5, 29, 61, 0.06)] rounded-[50px]"
                    >
                        <WarningCircle size={26} color="#FF4F44" weight="fill" />
                        <span> {errorText}</span>
                    </div>
                </Snackbar>
            )
        );
    else if (errorPosition === "undoapiErr")
        return (
            errorPosition === "undoapiErr" && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    TransitionComponent={transition}
                    open={errorPosition === "undoapiErr"}
                    autoHideDuration={1000}
                    onClick={() => dispatch(clearAdminError())}
                    onClose={() => dispatch(clearAdminError())}
                    message="I love snacks"
                    key={"top+center"}
                >
                    <div
                        className="h-11 gap-3 text-white subtitle justify-center items-center flex
        px-3 py-1
        bg-dark shadow-[0px 72px 132px rgba(5, 29, 61, 0.06)] rounded-[50px]"
                    >
                        <CheckCircle size={26} color="#00BA34" weight="fill" />
                        <span> {errorText}</span>
                    </div>
                </Snackbar>
            )
        );
    else if (errorPosition === "success")
        return (
            (errorPosition === "mainErr" ||
                errorPosition === "apiErr" ||
                errorPosition === "success") && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    TransitionComponent={transition}
                    open={
                        errorPosition === "mainErr" ||
                        errorPosition === "apiErr" ||
                        errorPosition === "success"
                    }
                    autoHideDuration={1000}
                    onClick={() => dispatch(clearAdminError())}
                    onClose={() => dispatch(clearAdminError())}
                    message="I love snacks"
                    key={"top+center"}
                >
                    <div
                        className="h-11 gap-3 text-white subtitle justify-center items-center flex
        px-3 py-1
        bg-primary shadow-[0px 72px 132px rgba(5, 29, 61, 0.06)] rounded-[50px]"
                    >
                        <CheckCircle size={26} color="#00BA34" weight="fill" />
                        <span> {errorText}</span>
                    </div>
                </Snackbar>
            )
        );
};

export const CircularProgressNovus = (props) => (
    <CircularProgress className="text-white" {...props} />
);

export const SpiceSlider = styled(Slider)(({ theme }) => ({
    color: "#EB222A",
    height: 5,
    "& .MuiSlider-thumb": {
        height: 27,
        width: 27,
        backgroundColor: "#fff",
        "& .airbnb-bar": {
            height: 9,
            width: 1,
            backgroundColor: "currentColor",
            marginLeft: 1,
            marginRight: 1,
        },
    },
    "& .MuiSlider-track": {
        height: 5,
    },
    "& .MuiSlider-rail": {
        color: "#CACFE3",
        opacity: theme.palette.mode === "dark" ? undefined : 1,
        height: 5,
    },
}));

export function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
        <SliderThumb {...other}>
            {children}
            <img src={fireEmoji} alt="" style={{ width: "50px", height: "50px" }} />
        </SliderThumb>
    );
}

// notify server button
export const NotifyWaiterComp = () => {
    const dispatch = useDispatch();
    return (
        <Button
            className="flex bg-primary self-end subtitle-semibold text-pure py-[6px] px-2 rounded-5"
            onClick={(e) => handleOpenNotifyWaiter(dispatch)}
        >
            Notify Waiter
        </Button>
    );
};

// ready for payment button
export const ReadyForPaymentComp = () => {
    const dispatch = useDispatch();
    return (
        <Button
            className="flex bg-primary btn-primary-large w-full"
            onClick={(e) => handleOpenNotifyWaiter(dispatch)}
        >
            Ready for Payment
        </Button>
    );
};

// filter drawer homepage
function searchVegItems(data) {
    const filteredData = data
        ?.map((category) => {
            // Filter items with at least one vegetarian variant
            const vegItems = category.items?.filter((item) =>
                item?.variants?.some((variant) => variant.isVeg===true)
            );

            // Create a new category object with filtered items
            if (vegItems && vegItems?.length > 0) {
                return { ...category, items: vegItems };
            }

            return null; // Skip categories with no vegetarian items
        })
        .filter(Boolean); // Remove null categories

    return filteredData;
}
function searchNonVegItems(data) {
    const filteredData = data
        ?.map((category) => {
            // Filter items with at least one vegetarian variant
            const vegItems = category.items?.filter((item) =>
                item?.variants?.some((variant) => variant.isVeg===false)
            );

            // Create a new category object with filtered items
            if (vegItems && vegItems?.length > 0) {
                return { ...category, items: vegItems };
            }

            return null; // Skip categories with no vegetarian items
        })
        .filter(Boolean); // Remove null categories

    return filteredData;
}

export default function FiltervegDrawer(data, setCategories, categories, state, setState) {
    const anchor = 'bottom'
    const toggleDrawer = (anchor, open) => {
        setState(x => ({ ...x, [anchor]: open }));
    };

    const handleCloseDrawer = () => {
        setState(x => ({ ...x, bottom: false }));
        if (state.showveg && state.showNonVeg) {
            setCategories(data);
        }
        else if (state.showveg) {
            const results = searchVegItems(data);
            setCategories(results);

        }
        else if (state.showNonVeg) {
            const results = searchNonVegItems(data);
            setCategories(results);
        }
        else { //all data
            setCategories(data);
        }
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            className='flex flex-col p-4 gap-2'
        >
            <span className="title-semibold">Filters</span>
            <List className="flex flex-col gap-4">
                <div className="flex items-center gap-2"
                    onClick={e => setState(x => ({ ...x, showveg: !state.showveg }))}
                >
                    <Checkbox className="p-0" label='veg' checked={state.showveg}
                        onChange={e => setState(x => ({ ...x, showveg: !state.showveg }))}
                    />
                    <span className="body-regular">Veg</span>
                </div>
                <div className="flex items-center gap-2"
                    onClick={e => setState(x => ({ ...x, showNonVeg: !state.showNonVeg }))}
                >
                    <Checkbox className="p-0" label='nonveg' checked={state.showNonVeg}
                        onChange={e =>
                            setState(x => ({ ...x, showNonVeg: !state.showNonVeg }))
                        } />
                    <span className="body-regular">Non-Veg</span>
                </div>
            </List>

        </Box>
    );

    return (
        <>
            <Button className="w-10 h-10 min-w-10 py-1 px-3 border_light-gray ml-auto rounded-xl"
                onClick={e => toggleDrawer(anchor, true)}>
                <SlidersHorizontal className="text-icon_default" />
            </Button>
            <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onOpen={e => toggleDrawer(anchor, true)}
                onClose={e => handleCloseDrawer()}
            >
                {list(anchor)}
            </SwipeableDrawer>
        </>
    );
}

export const SocialMediaComp = (prop) => {
    if (!prop) return null
    const filteredObject = Object.keys(prop).reduce((result, key) => {
        if (prop?.[key]?.showInCustomerApp === true) {
            result[key] = prop[key];
        }
        return result;
    }, {});
    var icon = null
    var url = ''
    if (filteredObject.tiktok) {
        icon = tiktok_icon
        url = filteredObject.tiktok.url
    }
    else if (filteredObject.facebook) {
        icon = facebook_icon
        url = filteredObject.facebook.url
    }
    else if (filteredObject.instagram) {
        icon = insta_icon
        url = filteredObject.instagram.url
    }
    return <a href={url} target="_blank" rel="noreferrer">
        <img src={icon} alt="" className="w-9 h-9" />
    </a>
}