import { createSlice } from '@reduxjs/toolkit'
import { getBusinessId, getTableId, getUserId } from '../../common/cookie'
import socketIOClient from "socket.io-client";
import { baseUrl } from '../../api/apiSlice';
const cookieName_cart = 'novus_user_cart'

export const counterSlice = createSlice({
    name: 'counter',
    initialState: {
        socialMedia: null,
        enableSocket: false,
        orderStatus: 'no_orders',
        businessId: getBusinessId(),
        tableId: getTableId(),
        notifyWaiter: false,
        errorPosition: '',
        errorText: '',
        n_count: 0,
        cart: JSON.parse(localStorage.getItem(cookieName_cart) || '{}'),
        checkContent : null,
        selectedService : null,
        socket_obj: getUserId() ? socketIOClient(baseUrl) : null,
    },
    reducers: {
        setError: (state, action) => {
            const { errorPosition, errorText } = action.payload
            state.errorPosition = errorPosition
            state.errorText = errorText
        },
        clearAdminError: (state, action) => {
            state.errorPosition = ''
            state.errorText = ''
        },
        // added to cart
        setCartItem: (state, action) => {
            const { cart } = action.payload
            state.cart = cart
        },
        updateCartInfo: (state, action) => {
            state.cart = action.payload
        },

        setOpenNotifyWaiter: (state, action) => {
            const { open, openAction } = action.payload
            state.notifyWaiter = open
            state.notifyWaiterAction = openAction
        },
        setEnableSocket: (state, action) => {
            state.enableSocket = true
        },
        setOrderStatus: (state, action) => {
            if(action?.payload?.includes('Order status is:')) {
                const orderStatus = "Order status is: preparing.";
                const extractedStatus = orderStatus.split(": ")[1].replace('.', ''); //
                state.orderStatus = extractedStatus
            }
            else state.orderStatus = action.payload
        },
        setBusinessTableId: (state, action) => {
            const { businessId, tableId } = action.payload
            state.businessId = businessId
            state.businessId = tableId
        },
        setTaxes: (state, action) => {
            const { taxes } = action.payload
            state.cart.taxes = taxes
        },
        setSocialMedia: (state, action) => {
            state.socialMedia = action.payload
        },
        setCheckContent: (state, action) => {
            state.checkContent = action.payload
        },
        setSelectedService: (state, action) => {
            state.selectedService = action.payload
        },
        setSocket: (state, action) => {
            state.socket_obj = socketIOClient(baseUrl)
        },
        clearSocket: (state, action) => {
            state.socket_obj?.emit("disconnection");
            state.socket_obj?.disconnect();
            state.socket_obj = null
        }
    },
})

export const { setError, clearAdminError,
    setSignOutCart, setCartItem, updateCartInfo,
    setOpenNotifyWaiter, setEnableSocket, setOrderStatus, setBusinessTableId, setTaxes,
    setSocialMedia, setCheckContent, setSelectedService,
    setSocket, clearSocket
} = counterSlice.actions

export const user_cart = (state) => state.counter.cart

export const ad_businessId = (state) => state.counter.businessId
export const ad_tableId = (state) => state.counter.tableId
export const ad_errorPosition = (state) => state.counter.errorPosition
export const ad_errorText = (state) => state.counter.errorText
export const ad_notifyWaiter = (state) => state.counter.notifyWaiter
export const ad_notifyWaiterAction = (state) => state.counter.notifyWaiterAction
export const ad_enableSocket = (state) => state.counter.enableSocket
export const ad_orderStatus = (state) => state.counter.orderStatus
export const ad_socialMedia = (state) => state.counter.socialMedia
export const ad_check = (state) => state.counter.checkContent
export const selected_service = (state) => state.counter.selectedService
export const socketObj = (state) => state.counter.socket_obj

export default counterSlice.reducer
