import { Avatar, Button, Container, Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import novus from "../../assets/novus.svg";
import whoopsie from "../../assets/whoopsie.svg";
import {
  getBusinessId,
  getTableId,
  saveScannedTable,
  setInitailCart,
} from "../../common/cookie";
import { useNotifyWaiterMutation } from "../slices/apiSlice";

function Occupied() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [openMembers, setOpenMembers] = useState(false);
  const stateData = location.state;
  const members = stateData?.customers ?? [];
  const [NotifyWaiter] = useNotifyWaiterMutation();
  useEffect(() => {
    if (!stateData) {
      navigate("/404");
    }
  }, [stateData, navigate]);
  const handleNo = async () => {
    try {
      await NotifyWaiter({
        businessId: getBusinessId(),
        id: getTableId(),
        action: "table_conflict",
      }).unwrap();
    } catch (error) {}
  };
  const handleContinue = () => {
    saveScannedTable(stateData.businessId, stateData.tableId, stateData.info);
    setInitailCart(stateData.businessId, stateData.tableId, dispatch);
    navigate("/landing");
  };
  return (
    <Container className="py-[3.25vh] flex flex-col overflow-hidden">
      {/* logo */}
      <Avatar
        src={stateData?.info?.businessInfo?.logo}
        alt="logo"
        variant="square"
        sx={{ width: "60px", height: "60px" }}
      />
      <h3 className="mt-6">
        Welcome to{" "}
        <span className="font-semibold">
          {stateData?.info?.businessInfo?.name}
        </span>
      </h3>
      {/* info */}
      <div className="flex flex-col gap-3 mx-2 p-[1.5rem] mt-10 rounded-xl border_light-gray text-center items-center">
        <span className="title-semibold">This table is already occupied</span>
        {members?.length > 0 && (
          <Button
            onClick={(e) => setOpenMembers(true)}
            className="flex gap-1 py-[3px] px-3 border border-solid 
        border-dark_gray w-fit rounded-3xl text-dark_gray subtitle-semibold"
          >
            <div className="flex gap-1 items-center">
              <Avatar
                src={members?.[0]?.image ?? ""}
                sx={{ width: 24, height: 24 }}
                alt="member image"
              />
              <div className="subtitle-semibold flex gap-1">
                {members?.[0]?.name.split(" ")?.[0]}
                {members?.length > 1 && (
                  <div>
                    and{" "}
                    <span className="text-link">
                      {members?.length - 1} others
                    </span>
                  </div>
                )}
              </div>
            </div>
          </Button>
        )}
        <span className="body-regular">
          {members?.length > 0 ? "Are you with them?" : "Continue the order?"}
        </span>
        <div className="flex gap-3 flex-1 w-full">
          <Button
            className="border border-solid border-primary py-[10px] body-semibold text-primary flex-1"
            onClick={(e) => {
              handleNo();
              navigate("/whoopsie");
            }}
          >
            No
          </Button>
          <Button
            className="flex-1 w-full bg-primary text-pure py-[10px] body-semibold"
            onClick={handleContinue}
          >
            Yes
          </Button>
        </div>
      </div>
      {/* powered by */}
      <div className="caption text-dark_gray flex items-center gap-1 self-center mt-auto">
        powered by <img src={novus} alt="" />
      </div>
      {/* members name drawer */}
      {members?.length > 0 &&
        MembersDrawer(members, openMembers, setOpenMembers)}
    </Container>
  );
}

export default Occupied;

const MembersDrawer = (members, openMembers, setOpenMembers) => {
  const anchor = "bottom";
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenMembers(open);
  };
  return (
    <Drawer
      anchor={anchor}
      open={openMembers}
      onClose={toggleDrawer(anchor, false)}
    >
      <div className="flex flex-col p-4 gap-3 rounded-[10px 10px 0 0] max-h-96 overflow-y-auto">
        <div className="w-11 h-[3px] bg-light_gray text-pure self-center">
          .
        </div>
        <span className="body-semibold">Members</span>
        <div className="flex flex-col gap-3">
          {members?.map((each, i) => (
            <div
              key={"each_member_" + i}
              className="flex gap-3 items-center body-regular"
            >
              <Avatar src={each.image} alt="member image" />
              <span className="capitalize">{each.name}</span>
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export const OccupiedWhoopsie = () => {
  return (
    <Container className="flex flex-col flex-1 justify-center items-center broken-gradient">
      <div className="flex flex-col w-[72%] gap-8 flex-1 items-center justify-center">
        <img src={whoopsie} alt="" className="" />
        <div className="flex flex-col items-center text-center mx-auto gap-2">
          <span className="title-semibold">QR whoopsie detected!</span>
          <span className="subtitle text-dark_gray">
            Don't fret, a server will soon untangle the tech tango.
          </span>
        </div>
      </div>
    </Container>
  );
};
