import { Box, Modal, Tab, Tabs, styled } from "@mui/material";

export const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#FF4F44",
  },
});

// tabs
export const EachTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    marginRight: theme.spacing(1),
    color: "#17181B",
    "&:hover": {
      color: "#17181B",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#17181B",
      fontWeight: "600 !important",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#17181B",
    },
  })
);

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// modal
export const MUIModal = (props) => {
  const { children, openModal, handleClose } = props;
  const style = {
    position: "absolute",
    width: "90vw",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFFFFF",
    borderColor: "#FFF",
    boxShadow: 24,
    maxHeight: "65vh",
    overflowX: "hidden",
    overflowY: "auto",
    // padding: "16px",
    gap: "16px",
    borderRadius: "12px",
  };
  return (
    <Modal
      keepMounted
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={style} className="loginCartModal d-flex flex-column">
        {children}
      </Box>
    </Modal>
  );
};
