import { Button, Container } from "@mui/material";
import broken from "../../assets/pencil_broken.svg";

export const FetchError = () => {
    return (
        <Container className="flex flex-col px-0 justify-center items-center mt-10 broken-gradient">
            <div className="flex flex-col w-full gap-8 flex-1 mt-20 items-center">
                <img src={broken} alt="" className="w-[100%]" />
                <div className="flex flex-col items-center text-center mx-auto gap-2 w-[90%]">
                    <span className="title-semibold">Whoops!</span>
                    <span className="subtitle text-dark_gray">
                        There was a problem fetching data. Please try again.
                    </span>
                </div>
            </div>
        </Container>
    );
};

export const BrokenPage = ({ onTryAgainClick }) => {
    return (
        <div className="flex flex-col justify-center">
            <div className="flex flex-col gap-8 flex-1 mt-20 broken-gradient">
                <img src={broken} alt="" className="w-[100vw] h-auto" />
                <div className="flex flex-col w-[255px] items-center text-center mx-auto gap-2">
                    <span className="title-semibold">Whoops!</span>
                    <span className="subtitle text-dark_gray">
                        There was a problem fetching data. Please try again.
                    </span>
                    <Button className="btn-primary-medium" onClick={onTryAgainClick}>Try Again</Button>
                </div>
            </div>
        </div>
    );
};

export const Error404 = () => {
    return (
        <Container className="flex flex-col px-0 justify-center items-center mt-10 broken-gradient">
            <div className="flex flex-col w-full gap-8 flex-1 mt-20 items-center">
                <img src={broken} alt="" className="w-[100%]" />
                <div className="flex flex-col items-center text-center mx-auto gap-2 w-[90%]">
                    <span className="title-semibold">Whoops!</span>
                    <span className="subtitle text-dark_gray">
                        Please try scanning again.
                    </span>
                </div>
            </div>
        </Container>
    );
};
